import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const Galery = ({ galeryImgs }) => {
  const settingsGaleria = {
    centerMode: false,
    centerPadding: "00px",
    slidesToShow: galeryImgs.length < 5 ? galeryImgs.length : 4,
    focusOnSelect: false,
    infinite: true,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: false,
          centerMode: true,
          autoplay: true,
          autoplaySpeed: 3000,
          centerPadding: "10px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: false,
          centerMode: true,
          autoplay: true,
          autoplaySpeed: 3000,
          centerPadding: "10px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="galeria container">
      <h2 className="subtitulo-verde">Galeria</h2>
      <div style={{ maxWidth: 286 * settingsGaleria.slidesToShow + "px" }}>
        <Slider {...settingsGaleria} className="galeria-center">
          {galeryImgs.map((img) => {
            return (
              <div key={img.alt}>
                <div className="galeria-box">
                  <div className="galeria-box-content">
                    <img src={img.src} alt={img.alt} />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <Link className="btn-sem-fundo-verde" to="/doacao">
        Seja um voluntário
      </Link>
    </section>
  );
};

export default Galery;

import React from "react";

const SvgContact = () => {
  return (
    <svg
      id="letters"
      xmlns="http://www.w3.org/2000/svg"
      width="18.608"
      height="13.789"
      viewBox="0 0 18.608 13.789"
    >
      <g id="Grupo_3106" data-name="Grupo 3106" transform="translate(0 0)">
        <path
          id="Caminho_2890"
          data-name="Caminho 2890"
          d="M16.972,61H3.816a1.616,1.616,0,0,0-1.635,1.591v.53H1.635A1.616,1.616,0,0,0,0,64.712V73.2a1.616,1.616,0,0,0,1.635,1.591H14.792A1.617,1.617,0,0,0,16.427,73.2v-.53h.545a1.616,1.616,0,0,0,1.635-1.591V62.591A1.616,1.616,0,0,0,16.972,61Zm-2.455,3.182L8.6,69.945a.556.556,0,0,1-.775,0L1.91,64.182ZM1.09,72.89V64.885l3.924,3.822Zm13.7.838H1.781l4.005-4.27,1.267,1.234a1.668,1.668,0,0,0,2.32,0l1.352-1.317,4.1,4.35Zm.545-1.026L11.5,68.626l3.841-3.741Zm2.181-1.626a.539.539,0,0,1-.545.53h-.545V64.712a1.616,1.616,0,0,0-1.635-1.591H3.271v-.53a.539.539,0,0,1,.545-.53H16.972a.539.539,0,0,1,.545.53Z"
          transform="translate(0 -61)"
          fill="#0d9648"
        />
      </g>
    </svg>
  );
};

export default SvgContact;

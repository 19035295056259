export default function getMatureYears() {
  const currentYear = new Date().getFullYear();
  const quantYears = 8;
  const years = [];

  for (let index = 0; index <= quantYears; index++) {
    years.push({ value: currentYear + index });
  }

  return years;
}

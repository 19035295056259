const PartnerItem = ({src, alt, link}) => {
    return <li>
        <a
        href={link}
        target="_blank"
        rel="noreferrer"
        >
        <img className="retornarImg" src={src} alt={alt} />
        </a>
    </li>
}

export default PartnerItem;
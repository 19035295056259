import React from "react";

const SvgEmaill = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.641"
      height="14.731"
      viewBox="0 0 19.641 14.731"
    >
      <path
        id="Icon_awesome-envelope"
        data-name="Icon awesome-envelope"
        d="M19.269,9.364a.231.231,0,0,1,.372.18v7.845A1.842,1.842,0,0,1,17.8,19.231H1.841A1.842,1.842,0,0,1,0,17.389V9.548a.229.229,0,0,1,.372-.18c.859.667,2,1.515,5.912,4.358.809.591,2.175,1.834,3.537,1.826,1.37.012,2.762-1.258,3.541-1.826C17.274,10.883,18.41,10.032,19.269,9.364ZM9.821,14.321c.89.015,2.171-1.12,2.816-1.588,5.091-3.694,5.478-4.016,6.652-4.937a.918.918,0,0,0,.353-.725V6.341A1.842,1.842,0,0,0,17.8,4.5H1.841A1.842,1.842,0,0,0,0,6.341V7.07A.924.924,0,0,0,.353,7.8C1.527,8.712,1.914,9.038,7,12.732,7.649,13.2,8.931,14.336,9.821,14.321Z"
        transform="translate(0 -4.5)"
        fill="#fff"
      />
    </svg>
  );
};

export default SvgEmaill;

import React from "react";
import { useApp } from "../../context/AppProvider";
import Modal from "./Modal";

const RegisterError = () => {
  const { registerError, setRegisterError } = useApp();

  return (
    <Modal visibility={registerError} setVisibility={setRegisterError}>
      <h3>Erro ao cadastrar</h3>

      <div className="modal-area">
        <p>
          Ocorreu algum erro durante o seu cadastro e não conseguimos
          completá-lo.
        </p>
      </div>
    </Modal>
  );
};

export default RegisterError;

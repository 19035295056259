import React, { useRef } from "react";
import * as yup from "yup";
import { Form } from "@unform/web";
import { useNavigate, useParams } from "react-router-dom";
import { novaSenhaSchema } from "../../utils/schemas";
import Password from "../../components/Form/Password";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import apis from "../../services/apis";
import { useApp } from "../../context/AppProvider";

const NovaSenha = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { hash } = useParams();
  const { setErrorTitle, setErrorDescription, setErrorMessage } = useApp();

  const handleFormSubmit = async (data, { reset }) => {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = yup.object().shape(novaSenhaSchema);
      await schema.validate(data, {
        abortEarly: false,
      });

      let passwordData = {
        hash: hash,
        password: data.newPassword
      }
      apis.put("/api/v1/passwords", passwordData).then(data => {
        setErrorTitle("Sucesso");
        setErrorDescription("Sua nova senha foi registrada com sucesso");
        setErrorMessage(true);
        navigate("/");
      }).catch(error => {
        console.error('Error changing password')
        setErrorTitle("Erro ao modificar senha");
        setErrorDescription(error.response.data.message);
        setErrorMessage(true);
        reset();
        navigate("/");
      });
    } catch (err) {
      const validationErrors = {};

      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div className="login">
      <SecondaryHeader title="Nova Senha" />

      <section className="login-content">
        <h2>Preencha os campos para criar sua nova senha.</h2>
        <Form onSubmit={handleFormSubmit} ref={formRef}>
          <Password name="newPassword" id="newPassword" label="Nova senha" />
          <Password
            name="confirmPassword"
            id="confirmPassword"
            label="Confirmar senha"
          />
          <button type="submit">ENTRAR</button>
        </Form>
      </section>
    </div>
  );
};

export default NovaSenha;

import React from "react";
import Header from "../../components/Header";
import Contact from "../../components/Contact";
import BlueWave from "../../components/BlueWave";
import Partner from "../../components/Partner";
import PostMidias from "../../components/PostMidias";
import SejaSocio from "../../components/Divisors/SejaSocio";
import Footer from "../../components/Footer";
import IniciativasComp from "../../components/IniciativasComp";
import FlexWrapper from "../../components/FlexWrapper";
import ImgAcao from "../../assets/images/foto-acoes.jpg";

const Iniciativas = () => {
  return (
    <div>
      <Header />
      <IniciativasComp />
      <section className="herois-portelinha container">
        <FlexWrapper version="8">
          <div>
            <h2 className="subtitulo-verde">HERÓIS DA PORTELINHA</h2>
            <p>
              Jorge Gomes, artista plástico e publicitário, criou a campanha
              para retratar o heroísmo das crianças em viver nas condições
              adversas em que vivem. A ideia surgiu através de fotos recebidas
              das crianças.
            </p>
            <p>
              As obras estão à venda em prol do projeto e das famílias das
              crianças, onde o recurso servirá como investimento para
              empreendedorismo.
            </p>
          </div>
          <div className="ifrane_container">
            <div className="iframe_wrapper">
              <iframe
                src="https://www.youtube.com/embed/eH3NF6WTpEg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </FlexWrapper>
      </section>

      <section className="separador container" />

      <section className="acoes">
        <div className="container">
          <div className="row gutters">
            <div className="col grid_6">
              <h2 className="subtitulo-verde">AÇÕES</h2>
              <p>
                Além do projeto de profissionalização, educação e atendimento
                psicológico oferecidos para a comunidade, os voluntários estão
                presentes duas vezes no mês para desenvolver atividades com as
                crianças. Arte, cultura e música são os temas. Sempre com um
                viés educacional.
              </p>

              <a className="btn-sem-fundo-verde" href="/">
                CONFIRA AS nossas AÇÕES
              </a>
            </div>
            <div className="col grid_6">
              <img src={ImgAcao} alt="Ação" />
            </div>
          </div>
        </div>
      </section>
      <SejaSocio />
      <PostMidias />
      <BlueWave />
      <Partner />
      <Contact />
      <Footer />
    </div>
  );
};

export default Iniciativas;

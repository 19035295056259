import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import FormRow from "./FormRow";
import SvgCheck from "../Svg/SvgCheck";

function RadioDonation({
  name,
  id,
  title,
  options,
  defaultChecked = 1,
  className = "",
  ...rest
}) {
  const inputRefs = useRef([]);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      path: "value",
      getValue: (refs) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value)[0];
      },
    });
  }, [fieldName, inputRefs, registerField, defaultValue]);

  return (
    <FormRow className="donation input-wrapper">
      <h3>{title}</h3>
      <ul>
        {options.map((item, index) => (
          <li key={item.label}>
            <input
              type="radio"
              id={`radio-${id}-${item.label}`}
              defaultChecked={index === defaultChecked}
              name={name}
              defaultValue={item.value}
              className={className}
              ref={(elRef) => (inputRefs.current[index] = elRef)}
              {...rest}
            />
            <label htmlFor={`radio-${id}-${item.label}`}>
              <span className="marker">
                <SvgCheck />
              </span>
              <span className="innerText">{item.label}</span>
            </label>
          </li>
        ))}
      </ul>
    </FormRow>
  );
}

export default RadioDonation;

import React from "react";

const ItemBalancete = ({title, path}) => {

    return <li>
    <a href={path} target="_blank" rel="noreferrer">
      {title}
    </a>
  </li>
}

export default ItemBalancete;
import React from "react";
import ImgCaixote from "../../../../assets/images/foto-destaque-caixote.jpg";
import ImgCaixote1 from "../../../../assets/images/foto-galeria-caixote-1.jpg";
import ImgCaixote2 from "../../../../assets/images/foto-galeria-caixote-2.jpg";
import ImgCaixote3 from "../../../../assets/images/foto-galeria-caixote-3.jpg";
import Galery from "../../../../components/Galery";

const galeryImgs = [
  { src: ImgCaixote1, alt: "Caixote do Saber 1" },
  { src: ImgCaixote2, alt: "Caixote do Saber 2" },
  { src: ImgCaixote3, alt: "Caixote do Saber 3" },
];

const CaixoteDoSaber = () => {
  return (
    <div className="detalhes-iniciativa">
      <main className="banner-detalhes-iniciativa-mobile" />

      <section className="detalhes-iniciativa-content container">
        <div className="row gutters">
          <div className="col grid_6">
            <h2 className="subtitulo-verde">CAIXOTE DO SABER</h2>
            <p>
              Neste momento de pandemia, para tirar um pouco as crianças da
              ociosidade, lançamos o projeto Caixote do Saber. Iniciativa de um
              dos voluntários do projeto e morador da favela, o objetivo é levar
              educação e cultura para pontos estratégicos da comunidade.
            </p>
            <p>
              Lá o morador poderá retirar o livro e devolve-lo no dia seguinte,
              ler no próprio local ou até mesmo ficar por mais tempo, com o
              compromisso e consciência social em deixa-lo de volta, ou até não,
              pois sendo livro, é educação, e nunca é demais. “A educação x
              futuro é o confronto mais saudável que a cultura pode
              proporcionar, e os livros te levam a isso.
            </p>
            <p>
              A Cidade de Deus é muito grande, e se muitos moradores não podem
              ir à biblioteca, por que não levá-la até elas?” diz André Melo.
            </p>
          </div>
          <div className="col grid_6">
            <img src={ImgCaixote} alt="Caixote do Saber" />
          </div>
        </div>
      </section>

      <Galery galeryImgs={galeryImgs} />
    </div>
  );
};

export default CaixoteDoSaber;

import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import FormRow from "./FormRow";

function RadioPaymentDay({
  name,
  id,
  title,
  options,
  defaultChecked = 1,
  className = "",
  ...rest
}) {
  const inputRefs = useRef([]);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      path: "value",
      getValue: (refs) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value)[0];
      },
    });
  }, [fieldName, inputRefs, registerField, defaultValue]);

  return (
    <FormRow className="maturity">
      <h3>{title}</h3>
      <ul>
        {options.map((item, index) => (
          <li key={item.value}>
            <input
              type="radio"
              id={`radio-${id}-${item.value}`}
              defaultChecked={index === defaultChecked}
              name={name}
              defaultValue={item.value}
              className={className}
              ref={(elRef) => (inputRefs.current[index] = elRef)}
              {...rest}
            />
            <label htmlFor={`radio-${id}-${item.value}`}>
              <span>{item.label || item.value}</span>
            </label>
          </li>
        ))}
      </ul>
    </FormRow>
  );
}

export default RadioPaymentDay;

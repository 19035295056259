import React from "react";
import ImgAulaIdiomas from "../../../../assets/images/foto-destaque-aula-idiomas.jpg";
import ImgAulaIdiomas1 from "../../../../assets/images/foto-galeria-aula-idiomas-1.jpg";
import ImgAulaIdiomas2 from "../../../../assets/images/foto-galeria-aula-idiomas-2.jpg";
import ImgAulaIdiomas3 from "../../../../assets/images/foto-galeria-aula-idiomas-3.jpg";
import Galery from "../../../../components/Galery";

const galeryImgs = [
  { src: ImgAulaIdiomas1, alt: "Aulas de Idiomas 1" },
  { src: ImgAulaIdiomas2, alt: "Aulas de Idiomas 2" },
  { src: ImgAulaIdiomas3, alt: "Aulas de Idiomas 3" },
];

const AulaIdiomas = () => {
  return (
    <div className="detalhes-iniciativa">
      <main className="banner-detalhes-iniciativa-mobile bg-aulas-de-idiomas"></main>

      <section className="detalhes-iniciativa-content container">
        <div className="row gutters">
          <div className="col grid_6">
            <h2 className="subtitulo-verde">Aulas de Idiomas</h2>
            <p>
              Inglês ou espanhol? Eles escolhem a forma como podem ter contato
              com outra cultura, língua e costumes. As aulas contam histórias de
              outros continentes.
            </p>
          </div>
          <div className="col grid_6">
            <img src={ImgAulaIdiomas} alt="Aulas de Idiomas" />
          </div>
        </div>
      </section>

      <Galery galeryImgs={galeryImgs} />
    </div>
  );
};

export default AulaIdiomas;

import React from "react";

const SvgAddress = () => {
  return (
    <svg
      id="pin"
      xmlns="http://www.w3.org/2000/svg"
      width="11.964"
      height="17.946"
      viewBox="0 0 11.964 17.946"
    >
      <g id="Group_3048" data-name="Group 3048" transform="translate(0)">
        <path
          id="Path_2886"
          data-name="Path 2886"
          d="M91.315,0a5.983,5.983,0,0,0-5.264,8.825l4.937,8.928a.374.374,0,0,0,.654,0l4.939-8.931A5.983,5.983,0,0,0,91.315,0Zm0,8.973a2.991,2.991,0,1,1,2.991-2.991A2.994,2.994,0,0,1,91.315,8.973Z"
          transform="translate(-85.333)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default SvgAddress;

import React from "react";
import ImgMidias1 from "../../assets/images/photo-midias-1.jpg";
import Slider from "react-slick";

const settingsPost = {
  centerMode: false,
  centerPadding: "20px",
  slidesToShow: 3,
  focusOnSelect: false,
  autoplay: true,
  autoplaySpeed: 3000,
  infinite: false,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        dots: true,
        centerMode: true,
        centerPadding: "10px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        dots: true,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
  ],
};

const PostMidias = () => {
  return (
    <section className="post-midias container">
      <Slider {...settingsPost} className="post-midias-center">

        <div className="post-midias-box">
          <div className="post-midias-box-content">
            <img src={ImgMidias1} alt="Foto Juliano" />
            <h3 className="subtitulo-midias">Juliano Rodrigues</h3>
            <p>
              “Eu sempre quis ajudar quem precisa, mas não sabia como até
              encontrar o Projeto Nóiz. Agora entendo por que ele tem esse nome.
              Porque o futuro depende de Nóiz.”
            </p>
            <span>Sócio Transformador desde maio 2020</span>
          </div>
        </div>

      </Slider>
    </section>
  );
};

export default PostMidias;

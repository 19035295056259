import React from "react";
import ImgNeuropediatrico from "../../../../assets/images/foto-destaque-neuropediatrico.jpg";
import ImgNeuropediatrico1 from "../../../../assets/images/foto-galeria-neuropediatrico-1.jpg";
import ImgNeuropediatrico2 from "../../../../assets/images/foto-galeria-neuropediatrico-2.jpg";
import Galery from "../../../../components/Galery";

const galeryImgs = [
  { src: ImgNeuropediatrico1, alt: "Neuropediatrico 1" },
  { src: ImgNeuropediatrico2, alt: "Neuropediatrico 2" },
];

const Neuropediatrico = () => {
  return (
    <div className="detalhes-iniciativa">
      <main className="banner-detalhes-iniciativa-mobile bg-neuropediatrico" />

      <section className="detalhes-iniciativa-content container">
        <div className="row gutters">
          <div className="col grid_6">
            <h2 className="subtitulo-verde">
              ATENDIMENTO BÁSICO E ENCAMINHAMENTO NEUROPEDIÁTRICO
            </h2>
            <p>
              Ser inclusivo não pode estar só no discurso. É preparar o espaço e
              ter uma equipe multidisciplinar qualificada para receber e
              orientar as famílias da comunidade que nunca tiveram acesso ao
              tratamento.
            </p>
          </div>
          <div className="col grid_6">
            <img src={ImgNeuropediatrico} alt="Neuropediatrico" />
          </div>
        </div>
      </section>

      <Galery galeryImgs={galeryImgs} />
    </div>
  );
};

export default Neuropediatrico;

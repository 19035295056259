import * as yup from "yup";
import { parse, isValid } from "date-fns";

yup.addMethod(yup.string, "isValidCpf", function (message) {
  return this.test({
    message,
    name: "isValidCpf",
    exclusive: true,
    test(value) {
      const strCPF = value.replace(/\D/g, "").padStart(11, "0");

      let Soma;
      let Resto;

      Soma = 0;
      if (strCPF === "00000000000") {
        return false;
      }

      for (let i = 1; i <= 9; i++) {
        Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      }

      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) {
        Resto = 0;
      }

      if (Resto !== parseInt(strCPF.substring(9, 10))) {
        return false;
      }

      Soma = 0;

      for (let i = 1; i <= 10; i++) {
        Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      }

      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) {
        Resto = 0;
      }

      if (Resto !== parseInt(strCPF.substring(10, 11))) {
        return false;
      }

      return true;
    },
  });
});

yup.addMethod(yup.string, "isValidZipcode", function (message) {
  return this.test({
    message,
    name: "isValidZipcode",
    exclude: true,
    test: async (value) => {
      const zipcode = value.replace(/\D/g, "");
      return zipcode.length === 8 ? true : false;
    },
  });
});

yup.addMethod(yup.string, "isValidPassport", function (message) {
  return this.test({
    message,
    name: "isValidPassport",
    exclude: true,
    test: async (value) => {
      return value.length === 8 ? true : false;
    },
  });
});

yup.addMethod(yup.string, "isValidaDate", function (message) {
  return this.test({
    message,
    name: "isValidaDate",
    exclusive: true,
    test(value) {
      const data = parse(value, "dd/MM/yyyy", new Date());

      if (!isValid(data)) {
        return false;
      }

      return true;
    },
  });
});

yup.addMethod(yup.string, "isTinyName", function (message) {
  return this.test({
    message,
    name: "isTinyName",
    exclusive: true,
    test(value) {
      const parsedValue = value.trim().split(" ");
      let isValidName = true;

      if (parsedValue.length <= 2) {
        parsedValue.forEach((currentValue) => {
          if (currentValue.length < 3) {
            isValidName = false;
          }
        });
      }

      return parsedValue.length >= 2 && isValidName;
    },
  });
});

yup.addMethod(yup.string, "isUnicName", function (message) {
  return this.test({
    message,
    name: "isUnicName",
    exclusive: true,
    test(value) {
      const parsedValue = value.trim().split(" ");
      let isValidName = true;

      if (parsedValue.length > 1) {
        parsedValue.forEach((currentValue) => {
          if (currentValue.length < 3) {
            isValidName = false;
          }
        });
      }

      return parsedValue.length === 1 && isValidName;
    },
  });
});

yup.addMethod(yup.string, "isCellPhone", function (message) {
  return this.test({
    message,
    name: "isCellPhone",
    exclusive: true,
    test(value = "") {
      const parsedValue = value.trim().replace(/\D/g, "");

      // Verifying if it is a cellphone number
      if (parsedValue.length === 11) {
        const ddd = parsedValue.substring(0, 2) * 1;

        // Does not exists DDD smaller than 11 in Brazil
        if (ddd < 11) {
          return false;
        }

        const thisNumber = parsedValue.substring(2);
        const firstDigit = thisNumber.substring(1, 0) * 1;

        if (firstDigit !== 9) {
          // It is not a valid cellphone in Brazil
          return false;
        }

        return true;
      }

      return false;
    },
  });
});

export const loginSchema = {
  email: yup
    .string()
    .email("Insira um email válido")
    .required("Insira seu email"),
  password: yup
    .string()
    .required("Insira sua senha")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "No mínimo: 8 caracteres, 1 maiúscula, 1 minúscula, 1 número e um caractere especial"
    ),
};

export const cadastroSchema = {
  name: yup.string().required("Insira seu nome"),
  lastName: yup.string().required("Insira seu sobrenome"),
  email: yup
    .string()
    .email("Insira um email válido")
    .required("Insira seu e-mail"),
  cpf: yup.string().when("nationality", (val) => {
    if (val === "nacional")
      return yup
        .string()
        .isValidCpf("Insira um CPF válido")
        .required("Insira seu CPF");
  }),
  issuerBank: yup.string().when("nationality", (val) => {
    if (val === "internacional")
      return yup.string().required("Selecione o país");
  }),
  passport: yup.string().when("nationality", (val) => {
    if (val === "internacional")
      return yup
        .string()
        .isValidPassport("Insira um passaporte válido")
        .required("Insira seu passaporte");
  }),
  phone: yup
    .string()
    .isCellPhone("Insira um número de telefone celular válido")
    .required("Insira um número de telefone celular"),
  cardNumber: yup.string().required("Insira o número do cartão de crédito"),
  cardName: yup.string().required("Insira o nome escrito no cartão de crédito"),
  maturyMonth: yup.string().required("Selecione o mês"),
  maturyYear: yup.string().required("Selecione o ano"),
  cvv: yup.string().min(3, "Mínimo de 3 digitos").required("Insira seu CVV"),
  zipcode: yup
    .string()
    .isValidZipcode("Insira um CEP válido")
    .required("Insira seu CEP"),
  address: yup.string().required("Insira o logradouro"),
  number: yup.string().required("Insira o número"),
  complement: yup.string().required("Insira o complemente"),
  state: yup.string().required("Insira o estato"),
  city: yup.string().required("Insira a cidade"),
};

export const contactSchema = {
  name: yup.string().required("Insira seu nome"),
  email: yup
    .string()
    .email("Insira um email válido")
    .required("Insira seu email"),
  subject: yup.string().required("Insira o assunto"),
  message: yup.string().required("Insira sua mensagem"),
};

export const novaSenhaSchema = {
  newPassword: yup
    .string()
    .required("Insira sua senha")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "No mínimo: 8 caracteres, 1 maiúscula, 1 minúscula, 1 número e um caractere especial"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Senha diferente"),
};

export const emailNovaSenhaSchema = {
  email: yup
    .string()
    .email("Insira um email válido")
    .required("Insira seu email"),
};

import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Form } from "@unform/web";
import { loginSchema } from "../../utils/schemas";
import Input from "../../components/Form/Input";
import Password from "../../components/Form/Password";
import apis from "../../services/apis";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import { useApp } from "../../context/AppProvider";

const Login = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { setErrorTitle, setErrorDescription, setErrorMessage } = useApp();
  const handleFormSubmit = async (data, { reset }) => {
    try {
      data.email = data.email.replace(/^\s+|\s+$/g, "");
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = yup.object().shape(loginSchema);

      await schema.validate(data, {
        abortEarly: false,
      });

      apis
        .post("/api/v1/login", data)
        .then((data) => {
          // Never store JWT in localStorage
          localStorage.setItem("accessToken", data.data.access_token);
          localStorage.setItem("user", JSON.stringify(data.data.user));
          navigate("/area-socio-transformador");
        })
        .catch((error) => {
          setErrorTitle("Erro ao logar");
          setErrorDescription("Seu e-mail ou senha está incorreto.");
          setErrorMessage(true);
          navigate("/");
        });
    } catch (err) {
      const validationErrors = {};

      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div className="login">
      <SecondaryHeader title="Entrar" />

      <section className="login-content">
        <h2>
          Para acessar a sua área restrita, insira seus dados para fazer o
          login.
        </h2>
        <Form onSubmit={handleFormSubmit} ref={formRef}>
          <Input name="email" id="email" label="E-mail" />
          <Password
            type="password"
            name="password"
            id="password"
            label="Senha"
          />

          <Link to="/email/recover">Esqueci minha senha</Link>
          <button type="submit">ENTRAR</button>
          <button
            className="secondary"
            type="button"
            onClick={() => navigate("/doacao")}
          >
            QUERO ME CADASTRAR
          </button>
        </Form>
      </section>
    </div>
  );
};

export default Login;

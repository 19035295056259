import React from "react";
import Logo from "../../assets/images/logo-noiz.png";
import { Link } from "react-router-dom";
import SvgClose from "../Svg/SvgClose";

const SecondaryHeader = ({ title }) => {
  return (
    <header className="secondary-header">
      <div className="container">
        <Link to="/" className="header-logo">
          <img src={Logo} alt="Noiz" />
        </Link>
        <h2 className="header-title">{title}</h2>
        <Link className="header-close" to="/">
          <SvgClose />
        </Link>
      </div>
    </header>
  );
};

export default SecondaryHeader;

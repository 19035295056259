import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Form } from "@unform/web";
import Input from "../../components/Form/Input";
import { cadastroSchema } from "../../utils/schemas";
import InputMask from "../../components/Form/InputMask";
import Select from "../../components/Form/Select";
import apis from "../../services/apis";
import HelpMessage from "../../components/HelpMessage";
import RadioPaymentDay from "../../components/Form/RadioPaymentDay";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import Bugsnag from "@bugsnag/js";
import { useApp } from "../../context/AppProvider";
import RadioDonation from "../../components/Form/RadioDonation";
import getMatureYears from "../../utils/matureYears";
import Link from "../../components/Form/Link";
import { createSubscriptions, createUser } from "../../utils/factories";
import {
  donationValues,
  initialStateAddress,
  maturyMonths,
  paymentDays,
  radioValues,
} from "../../utils/constantes";
import Radio from "../../components/Form/Radio";
import { countries } from "../../utils/countries";

const maturyYears = getMatureYears();

const Cadastro = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const {
    setErrorTitle,
    setErrorDescription,
    setErrorMessage,
    setLoading,
    loading,
  } = useApp();

  const [address, setAddress] = useState(() => initialStateAddress);

  const [values, setValues] = useState({
    nationality: "nacional",
    passport: "",
  });

  function handleChange(e) {
    setValues((state) => ({ ...state, [e.target.name]: e.target.value }));
  }

  function setUpperCase(e) {
    setValues((state) => ({
      ...state,
      [e.target.name]: e.target.value.toUpperCase(),
    }));
  }

  const handleFormSubmit = async (data) => {
    try {
      setLoading(true);
      data.email = data.email.replace(/^\s+|\s+$/g, "");
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = yup.object().shape(cadastroSchema);
      await schema.validate(data, {
        abortEarly: false,
      });

      const user = createUser(data);
      localStorage.setItem("user", JSON.stringify(user));

      const subscriptions = createSubscriptions(data);

      apis
        .post("/api/v1/subscriptions", subscriptions)
        .then((data) => {
          setLoading(false);
          navigate("/sucesso");
        })
        .catch((error) => {
          setErrorTitle("Erro na inscrição");
          setErrorDescription(error.response.data.message);
          setErrorMessage(true);
          setLoading(false);
          navigate("/");
        });
    } catch (error) {
      console.log(error);
      const validationErrors = {};
      Bugsnag.notify(error, (event) => {
        event.context = "Cadastro handleFormSubmit";
      });
      setLoading(false);
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const zipcodeChange = async (event) => {
    const zipcode = event.currentTarget.value.replace(/[\D]+/g, "");
    if (zipcode.length >= 8) {
      await apis
        .get(`/api/v1/addresses?zipcode=${zipcode}`)
        .then((response) => {
          setAddress(response.data);
        });
    }
  };

  return (
    <div className="cadastro">
      <SecondaryHeader title="cadastro" />

      <section className="form-cadastro container">
        <div className="row gutters">
          <div className="col grid_7">
            <h2>
              Para ser um Sócio Transformador, preencha os dados abaixo. É
              rápido e simples.
            </h2>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <h3 className="form-title">DADOS PESSOAIS</h3>
              <div className="flex-wrapper-half">
                <Input name="name" id="name" label="Nome" />
                <Input name="lastName" id="lastName" label="Sobrenome" />
              </div>
              <InputMask
                label="Telefone (somente celular/móvel)"
                name="phone"
                id="phone"
                maskChar={null}
                mask={"(99) 99999-9999"}
              />
              <Input name="email" id="email" label="E-mail" />

              <h3 className="form-title">DADOS DE PAGAMENTO</h3>
              <RadioDonation
                name="donation"
                id="donation"
                title="Escolha o valor de sua doação:"
                defaultChecked={0}
                options={donationValues}
              />
              <Radio
                name="nationality"
                id="nationality"
                options={radioValues}
                onChange={handleChange}
              />
              <InputMask
                name="cardNumber"
                id="cardNumber"
                label="Número do cartão de crédito"
                mask="9999999999999999"
                maskChar={null}
              />
              <Input
                name="cardName"
                id="cardName"
                label="Nome (como escrito no cartão)"
              />
              <div className="flex-wrapper">
                <Select
                  label="Validade"
                  name="maturyMonth"
                  placeholder="Mês"
                  options={maturyMonths}
                />
                <Select
                  label="Validade"
                  name="maturyYear"
                  placeholder="Ano"
                  labelHidden={true}
                  options={maturyYears}
                />
                <InputMask
                  type="password"
                  name="cvv"
                  id="cvv"
                  label="CVV"
                  mask="999"
                  maskChar={null}
                />
                <HelpMessage />
              </div>

              {values.nationality === "nacional" ? (
                <InputMask
                  label="CPF"
                  name="cpf"
                  id="cpf"
                  maskChar={null}
                  mask="999.999.999-99"
                />
              ) : (
                <>
                  <Select
                    name="issuerBank"
                    id="issuerBank"
                    onChange={setUpperCase}
                    placeholder="Escolha o país"
                    label="País de origem do banco emissor"
                    options={countries}
                  />
                  <InputMask
                    label="N° do Passaporte"
                    name="passport"
                    id="passport"
                    mask="aa999999"
                    value={values.passport}
                    onChange={setUpperCase}
                    maskChar={null}
                  />
                </>
              )}

              <RadioPaymentDay
                name="paymentDay"
                id="paymentDay"
                title="Dia do vencimento"
                defaultChecked={0}
                options={paymentDays}
              />

              <h3 className="form-title">ENDEREÇO DE COBRANÇA</h3>
              <InputMask
                label="CEP"
                name="zipcode"
                id="zipcode"
                mask="99.999-999"
                maskChar={null}
                onChange={(e) => zipcodeChange(e)}
              >
                <Link
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  Não sei o meu CEP
                </Link>
              </InputMask>
              <Input
                label="Logradouro"
                name="address"
                id="address"
                onChange={(_) => {}}
                value={address.address}
              />
              <div className="flex-wrapper-half">
                <InputMask
                  label="Número"
                  name="number"
                  id="number"
                  mask="999999999999999"
                  maskChar={null}
                />
                <Input label="Complemento" name="complement" id="complement" />
              </div>
              <div className="flex-wrapper-half">
                <InputMask
                  label="Estado"
                  name="state"
                  id="state"
                  mask="aa"
                  maskChar={null}
                  onChange={(_) => {}}
                  value={address.state}
                />
                <Input
                  label="Cidade"
                  name="city"
                  id="city"
                  onChange={(_) => {}}
                  value={address.city}
                />
              </div>
              <div className="row gutters">
                <div className="col grid_6">
                  <button disabled={loading}>
                    {loading ? "Carregando..." : "Ajudar"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Cadastro;

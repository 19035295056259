import React from "react";

const SvgCheck = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.202 0.446348C15.5131 0.156893 15.927 -0.00312984 16.3565 4.6394e-05C16.7859 0.00322263 17.1973 0.16935 17.5038 0.463373C17.8104 0.757396 17.9881 1.15632 17.9994 1.57596C18.0108 1.9956 17.8549 2.40315 17.5647 2.71259L8.75442 13.4826C8.60293 13.6421 8.42008 13.7701 8.21682 13.859C8.01356 13.9478 7.79406 13.9957 7.57144 13.9997C7.34882 14.0038 7.12766 13.9639 6.92117 13.8824C6.71469 13.801 6.52712 13.6797 6.36969 13.5258L0.527089 7.81487C0.364382 7.66668 0.233879 7.48797 0.143366 7.2894C0.0528517 7.09084 0.00418102 6.87649 0.000257728 6.65914C-0.00366557 6.44179 0.037239 6.22589 0.12053 6.02433C0.203822 5.82277 0.327794 5.63967 0.485051 5.48596C0.642308 5.33224 0.829628 5.21107 1.03584 5.12965C1.24205 5.04824 1.46292 5.00825 1.68528 5.01209C1.90764 5.01592 2.12693 5.0635 2.33008 5.15197C2.53322 5.24045 2.71605 5.36801 2.86766 5.52705L7.4914 10.0444L15.1601 0.493832C15.1739 0.477215 15.1864 0.461362 15.202 0.446348Z"
        fill="#0D9648"
      />
    </svg>
  );
};

export default SvgCheck;

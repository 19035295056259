import React from "react";
import Slider from "react-slick";
import JuanSilvaBoxe from "../../assets/images/JuanSilvaBoxe.png";
import ImgComunidade from "../../assets/images/meia-hora-alo-comunidade.jpg";
import ImgG1 from "../../assets/images/g1.jpg";
import ImgR7 from "../../assets/images/r7.jpg";
import ImgGloboplay from "../../assets/images/globoplay.jpg";
import ImgNoticia2 from "../../assets/images/sonoticiaboa2.jpg";
import ImgViz from "../../assets/images/viz.jpg";
import ImgNoticia3 from "../../assets/images/sonoticiaboa.jpg";
import ImgMeiaHora from "../../assets/images/meia_hora.jpg";
import ImgBalancoGeral from "../../assets/images/balanco_geral_rio.jpg";
import ImgColunaBairro5 from "../../assets/images/coluna_bairros05.jpg";
import ImgColunaBairro4 from "../../assets/images/coluna_bairros04.jpg";
import ImgColunaBairro3 from "../../assets/images/coluna_bairros03.jpg";
import ImgColunaBairro2 from "../../assets/images/coluna_bairros02.jpg";
import ImgColunaBairro1 from "../../assets/images/coluna_bairros01.jpg";
import ImgMarinaCaruso from "../../assets/images/materia_marina_caruso.jpg";
import ImgBlog5 from "../../assets/images/img_blog5.jpg";
import ImgBlog1 from "../../assets/images/img_blog1.jpg";
import ImgNoticiaBoa from "../../assets/images/so-noticia-boa.png";
import ImgEstadaoExpresso from "../../assets/images/estadao_expresso.png";
import ImgNationalRio from "../../assets/images/national_rio.png";
import ImgExtraUniversidades from "../../assets/images/extra-universidades.png";
import SonhosMerecemViver from "../../assets/images/NoizSonhos.jpg";
import Sextou5 from "../../assets/images/Sextou5.jpg";
import BlogItem from "../BlogItem";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

const blogItems = [
  { title: "Juan e sua trajetória de luta pelo esporte.!", link: "https://youtu.be/nChi_sfGq9A?feature=shared", img: JuanSilvaBoxe},
  { title:"Projetos Nóiz - Aulas Grátis", link: " https://g1.globo.com/rj/rio-de-janeiro/bom-dia-rio/video/sextou-confira-as-dicas-culturais-do-bdrj-para-o-fim-de-semana-11762640.ghtml", img: Sextou5},
  { title:"Extra - Rio de Janeiro", link: "https://extra.globo.com/rio/casos-de-policia/noticia/2023/04/ong-na-cidade-de-deus-instala-painel-apos-ser-atingida-por-tiros-sonhos-merecem-viver.ghtml", img: SonhosMerecemViver },
  { title:"Extra - Rio de Janeiro", link: "https://extra.globo.com/noticias/rio/mulheres-da-cidade-de-deus-superam-adversidades-conquistam-vagas-em-universidades-federais-25456041.html", img: ImgExtraUniversidades },
  { title:"G1 - Rio de Janeiro", link: "https://g1.globo.com/rj/rio-de-janeiro/noticia/2022/03/11/menino-da-cidade-de-deus-reproduz-famosa-foto-de-afega-na-capa-da-national-geographic.ghtml", img: ImgNationalRio },
  { title:"Estadão Expresso - Estadão", link: "https://expresso.estadao.com.br/naperifa/biblioteca-itinerante-incentiva-a-leitura-na-cidade-de-deus/", img: ImgEstadaoExpresso },
  { title:"Só Notícia Boa - Boas Ações", link: "https://www.sonoticiaboa.com.br/2021/10/12/ong-cidade-de-deus-resgata-promessa-boxe-sinal/", img: ImgNoticiaBoa },
  { title:"Meia Hora - Alô Comunidade", link: "https://www.meiahora.com.br/alo-comunidade/2021/01/6068189-resgatando-sorrisos.html#foto=1", img: ImgComunidade },
  { title:"G1 - Rio de Janeiro", link: "https://g1.globo.com/rj/rio-de-janeiro/noticia/2020/07/15/ong-instala-minibiblioteca-feita-de-caixotes-na-cidade-de-deus.ghtml", img: ImgG1 },
  { title:"R7 - Domingo Espetacular", link: "https://recordtv.r7.com/domingo-espetacular/videos/mulher-salva-bebe-de-incendio-em-comunidade-no-rio-de-janeiro-26102020", img: ImgR7 },
  { title:"Globo Comunidade", link: "https://globoplay.globo.com/v/8761376/programa/", img: ImgGloboplay },
  { title:"Só Notícia Boa - Brasil", link: "https://www.sonoticiaboa.com.br/2020/08/02/discurso-de-mata-nao-espalhe-amor-nas-redes-campanha/", img: ImgNoticia2 },
  { title:"Vóz das Comunidades", link: "https://www.vozdascomunidades.com.br/comunidades/ong-da-cidade-de-deus-cria-biblioteca-para-moradores-em-maior-vulnerabilidade-social/", img: ImgViz },
  { title:"Só Notícia Boa - Boas Ações", link: "https://www.sonoticiaboa.com.br/2020/07/04/caixote-saber-criancas-extase-cidade-deus-livros/", img: ImgNoticia3 },
  { title:"Jornal Meia Hora - Geral", link: "https://www.meiahora.com.br/geral/2020/06/5941715-pais-de-joao-pedro-vao-a--ong-noiz--conferir-homenagem-ao-filho.html", img: ImgMeiaHora },
  { title:"Balanço Geral RJ", link: "https://recordtv.r7.com/balanco-geral-rj/videos/coronavirus-ongs-criam-forca-tarefa-para-ajudar-moradores-da-cidade-de-deus-03042020", img: ImgBalancoGeral },
  { title:"O Globo", link: "https://oglobo.globo.com/rio/moradores-de-localidade-mais-carente-da-cidade-de-deus-recebem-200-cestas-basicas-1-24327314", img: ImgColunaBairro5 },
  { title:"O Globo", link: "https://oglobo.globo.com/rio/essencia-da-cidade-de-deus-donas-de-casa-fabricam-perfumes-com-treinamento-de-empresa-francesa-1-24111492", img: ImgColunaBairro4 },
  { title:"O Globo", link: "https://oglobo.globo.com/rio/brejo-localidade-da-cidade-de-deus-tera-intervencao-artistica-no-proximo-sabado-23959071", img: ImgColunaBairro3 },
  { title:"O Globo - Bairros", link: "https://oglobo.globo.com/rio/bairros/ong-noiz-na-cidade-de-deus-solicita-voluntarios-para-aulas-de-alfabetizacao-23361588", img: ImgColunaBairro2 },
  { title:"O Globo - Bairros", link: "https://oglobo.globo.com/rio/bairros/projetos-sociais-recem-criados-na-barra-saem-do-papel-apesar-da-crise-22946520", img: ImgColunaBairro1 },
  { title:"O Globo - Coluna Marina Caruso", link: "https://blogs.oglobo.globo.com/marina-caruso/post/criancas-da-cidade-de-deus-ganharao-visual-de-idolos-como-neymar.html", img: ImgMarinaCaruso },
  { title:"Matéria Capa Globo", link: "https://oglobo.globo.com/rio/populacao-miseravel-vive-esquecida-pelo-estado-em-favela-nascida-nas-olimpiadas-22972675", img: ImgBlog5 },
  { title:"Nota Alcemo Gois", link: "https://blogs.oglobo.globo.com/ancelmo/post/artista-transforma-criancas-de-favela-do-rio-em-super-herois.html", img: ImgBlog1 }
]

const Blog = () => {
  return (
    <div className="blog" id="blog">
      <div className="container">
        <h2 className="subtitulo-verde">Nóiz na mídia</h2>

        <Slider {...settings} className="blog-center">

        {blogItems.map( item => <BlogItem 
              key={item.title}
              title={item.title}
              image={item.img}
              link={item.link}
            />
          )}       
          
        </Slider>

        <div id="contactSection"></div>
      </div>
    </div>
  );
};

export default Blog;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import apis from "../../services/apis";

const SejaSocio = () => {
  const [ totalUsers, setTotalUsers ] = useState(0);
  useEffect(_ => {
    apis.get('/api/v1/users/total').then(data => {
      setTotalUsers(data.data.total_subscriptions)
    }).catch(error => {
      console.error('Error comunicating with API: /users/total');
    });
  }, []);

  return (
    <section className="seja-socio">
      <div className="seja-socio-content-mobile container">
        <h2>Ajude</h2>
        <p>
          Para maior praticidade, usamos os principais cartões de crédito
          disponíveis.
        </p>
        <Link className="btn-gradient-amarelo" to="/doacao">
          R$ 10 POR MÊS
        </Link>
      </div>
    </section>
  );
};

export default SejaSocio;

import React, { useEffect, useState } from "react";
import LogoSocio from "../../assets/images/logo-socio.png";
import IcoFacebook from "../../assets/images/ico-facebook.svg";
import IcoWhatsApp from "../../assets/images/ico-wpp.svg";
import IcoTwitter from "../../assets/images/ico-twitter.svg";
import IcoInstagram from "../../assets/images/ico-instagram.svg";
import IcoTelegram from "../../assets/images/ico-telegram.svg";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import { hasSelectionSupport } from "@testing-library/user-event/dist/utils";

const monthNames = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];

const CadastroSucesso = () => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [today] = useState(new Date());

  useEffect(_ => {
      let user = JSON.parse(localStorage.getItem("user"));
      setName('name' in user ? user.name: '');
      setLastName('lastName' in user ? user.lastName : '');
  }, []);

  return (
    <div className="cadastro">
      <SecondaryHeader title="SÓCIO TRANSFORMADOR" />

      <section className="sucesso container">
        <div className="col grid_8">
          <p>Parabéns! Você é um Sócio Transformador. Mais uma vez obrigado!</p>
          <p>
            Você receberá um e-mail com a confirmação da sua contribuição e uma
            senha para acessar a área exclusiva do nosso site e acompanhar o
            extrato e a prestação de contas.
          </p>
        </div>
      </section>

      <section className="credencial container">
        <div className="row gutters">
          <div className="credencial-content col grid_6">
            <h3>{name} {lastName}</h3>
            <img src={LogoSocio} alt="Sócio Transformador" />
            <p>Desde { monthNames[today.getMonth()] } de { today.getFullYear() }</p>
          </div>
          <div className="credencial-share col grid_6">
            <p>
              Compartilhe sua credencial de sócio e ajude a divulgar nosso
              trabalho.
            </p>
            <h3>Compartilhe</h3>
            <ul>
              <li>
                <a
                    href="https://pt-br.facebook.com/pages/category/Social-Service/N%C3%93IZ-Projeto-Social-200939380531515/"
                    target="_blank"
                    rel="noreferrer"
                  >
                  <img src={IcoFacebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                    href="https://www.instagram.com/noizprojetosocial/"
                    target="_blank"
                    rel="noreferrer"
                  >
                  <img src={IcoInstagram} alt="Instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CadastroSucesso;

import React, { useEffect, useRef } from "react";
import ReactInputMask from "react-input-mask";
import { useField } from "@unform/core";
import ErrorMessage from "./ErrorMessage";
import FormRow from "./FormRow";
import Label from "./Label";

const InputMask = ({
  type = "text",
  id,
  name,
  mask,
  maskChar,
  label = null,
  placeholder = "",
  defaultValue = "",
  children,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="input-wrapper">
      {label && <Label htmlFor={id}>{label}</Label>}
      <FormRow>
        <ReactInputMask
          type={type}
          id={id}
          name={name}
          mask={mask}
          maskChar={maskChar}
          ref={inputRef}
          className={error ? "error" : ""}
          placeholder={placeholder}
          // defaultValue={defaultValue}
          {...rest}
        />
        {children}
      </FormRow>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export default InputMask;

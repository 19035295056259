export const maturyMonths = [
  { value: "01", label: "01 - Janeiro" },
  { value: "02", label: "02 - Fevereiro" },
  { value: "03", label: "03 - Março" },
  { value: "04", label: "04 - Abril" },
  { value: "05", label: "05 - Maio" },
  { value: "06", label: "06 - Junho" },
  { value: "07", label: "07 - Julho" },
  { value: "08", label: "08 - Agosto" },
  { value: "09", label: "09 - Setembro" },
  { value: "10", label: "10 - Outubro" },
  { value: "11", label: "11 - Novembro" },
  { value: "12", label: "12 - Dezembro" },
];

export const paymentDays = [
  { value: "02" },
  { value: "05" },
  { value: "10" },
  { value: "15" },
  { value: "20" },
  { value: "26" },
  { value: "28" },
];

export const donationValues = [
  { label: "R$ 50,00", value: 50 },
  { label: "R$ 30,00", value: 30 },
  { label: "R$ 10,00", value: 10 },
];

export const radioValues = [
  { label: "Cartão Emitido <b>no Brasil</b>", value: "nacional" },
  { label: "Cartão Emitido <b>Fora do Brasil</b>", value: "internacional" },
];

export const initialStateAddress = {
  zipcode: "",
  state: "",
  city: "",
  address: "",
  district: "",
  country: "nacional",
};

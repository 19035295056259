import { useField } from "@unform/core";
import React, { useEffect, useRef } from "react";
import ErrorMessage from "./ErrorMessage";
import Label from "./Label";

const Radio = ({ name, options = [], label, id, ...props }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: (refs) => {
        return refs.current.find((input) => input?.checked)?.value;
      },
      setValue: (refs, id) => {
        const inputRef = refs.current.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs) => {
        const inputRef = refs.current.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="radio input-wrapper">
      {label && <Label htmlFor={id}>{label}</Label>}
      <div>
        {options.map((option, index) => {
          return (
            <label htmlFor={`${id}${index}`} key={option.value}>
              <input
                id={`${id}${index}`}
                name={name}
                type="radio"
                ref={(ref) => {
                  inputRefs.current[index] = ref;
                }}
                value={option.value}
                defaultChecked={index === 0}
                {...props}
              />
              <strong />
              <span
                dangerouslySetInnerHTML={{
                  __html: option.label || option.value,
                }}
              />
            </label>
          );
        })}
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export default Radio;

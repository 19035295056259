import React from "react";
import { useApp } from "../../context/AppProvider";
import Modal from "./Modal";

const ErrorMessage = () => {
  const { errorMessage, setErrorMessage, errorTitle, errorDescription } = useApp();

  return (
    <Modal visibility={ errorMessage } setVisibility={ setErrorMessage }>
      <h3>{ errorTitle }</h3>

      <div className="modal-area">
        <p>{ errorDescription }</p>
      </div>
    </Modal>
  );
};

export default ErrorMessage;

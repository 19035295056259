import { countries } from "./countries";

export function createSubscriptions(data) {
  console.log(data)

  const subscription = {
    card: {
      number: data.cardNumber,
      holder_name: data.cardName,
      exp_month: data.maturyMonth,
      exp_year: data.maturyYear,
      cvv: data.cvv,
    },
    user: {
      email: data.email,
      name: data.name,
      lastname: data.lastName,
      phone: data.phone.replace(/[^0-9]+/g, ""),
    },
    address: {
      state: data.state,
      city: data.city,
      zip_code: data.zipcode.replace(/[^0-9]+/g, ""),
      address: `${data.number}, ${data.address}, ${data.complement}`,
      country: countries.find(c => c.value === (data.issuerBank || "Brasil")).code,
    },
    billing_day: data.paymentDay,
    plan_value: data.donation
  };

  if (data.nationality === "nacional") {
    subscription.user.document = data.cpf.replace(/[^0-9]+/g, "");
    subscription.user.document_type = "cpf";
  } else if (data.nationality === "internacional") {
    subscription.user.document = data.passport;
    subscription.user.document_type = "passport";
  }

  return subscription;
}

export function createUser(data) {
  const user = {
    name: data.name,
    last_name: data.lastName,
  };

  return user;
}

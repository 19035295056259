import React from "react";
import Footer from "../../../components/Footer";
import Contact from "../../../components/Contact";
import BlueWave from "../../../components/BlueWave";
import Partner from "../../../components/Partner";
import PostMidias from "../../../components/PostMidias";
import SejaSocio from "../../../components/Divisors/SejaSocio";
import Header from "../../../components/Header";
import { Navigate, useParams } from "react-router-dom";

import AtendimentoMedico from "./AtendimentoMedico";
import CaixoteDoSaber from "./CaixoteDoSaber";
import AtendimentoPsico from "./AtendimentoPsico";
import AulaTeatro from "./AulaTeatro";
import AulaIdiomas from "./AulaIdiomas";
import AulaReforco from "./AulaReforco";
import CursosProf from "./CursosProf";
import GraduaNoiz from "./GraduaNoiz";
import Neuropediatrico from "./Neuropediatrico";
import SessaoCinema from "./SessaoCinema";

const subpages = {
  "atendimento-medico": AtendimentoMedico,
  "atendimento-psicologico": AtendimentoPsico,
  "aulas-de-teatro": AulaTeatro,
  "aulas-de-idiomas": AulaIdiomas,
  "aulas-de-reforco": AulaReforco,
  "cursos-profissionalizantes": CursosProf,
  "caixote-do-saber": CaixoteDoSaber,
  "gradua-noiz": GraduaNoiz,
  neuropediatrico: Neuropediatrico,
  "sessao-de-cinema": SessaoCinema,
};

const Iniciativa = () => {
  let { iniciativaId } = useParams();
  const Content = subpages[iniciativaId];

  if (!Content) return <Navigate to="/iniciativas" />;

  return (
    <>
      <Header />
      <Content />
      <SejaSocio />
      <PostMidias />
      <BlueWave />
      <Partner />
      <Contact />
      <Footer />
    </>
  );
};

export default Iniciativa;

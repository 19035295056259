import React from "react";
import ImgAttendMedico from "../../../../assets/images/foto-destaque-atendimento-medico-.jpg";
import ImgAttendMedico1 from "../../../../assets/images/foto-galeria-atendimento-medico-1.jpg";
import ImgAttendMedico2 from "../../../../assets/images/foto-galeria-atendimento-medico-2.jpg";
import ImgAttendMedico3 from "../../../../assets/images/foto-galeria-atendimento-medico-3.jpg";
import Galery from "../../../../components/Galery";

const galeryImgs = [
  { src: ImgAttendMedico1, alt: "Atendimento Médico 1" },
  { src: ImgAttendMedico2, alt: "Atendimento Médico 2" },
  { src: ImgAttendMedico3, alt: "Atendimento Médico 3" },
];

const AtendimentoMedico = () => {
  return (
    <div className="detalhes-iniciativa">
      <main className="banner-detalhes-iniciativa-mobile bg-atendimento-medico" />

      <section className="detalhes-iniciativa-content container">
        <div className="row gutters">
          <div className="col grid_6">
            <h2 className="subtitulo-verde">Atendimento médico</h2>
            <p>
              Saúde é o nosso maior bem. Por isso, o acompanhamento médico é
              muito importante ao longo de toda a vida, tanto para o tratamento
              como para a prevenção de doenças.
            </p>
          </div>
          <div className="col grid_6">
            <img src={ImgAttendMedico} alt="Atendimento Médico" />
          </div>
        </div>
      </section>

      <Galery galeryImgs={galeryImgs} />
    </div>
  );
};

export default AtendimentoMedico;

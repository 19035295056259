import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Form } from "@unform/web";
import { emailNovaSenhaSchema } from "../../utils/schemas";
import Input from "../../components/Form/Input";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import apis from "../../services/apis";
import { useApp } from "../../context/AppProvider";


const EmailNovaSenha = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { setErrorTitle, setErrorDescription, setErrorMessage } = useApp();

  const handleFormSubmit = async (data, { reset }) => {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = yup.object().shape(emailNovaSenhaSchema);
      await schema.validate(data, {
        abortEarly: false,
      });
      apis.post("/api/v1/passwords/recover", data).then(data => {
        setErrorTitle("Troca de senha");
        setErrorDescription(
          "Enviamos um e-mail para você. " +
          "Siga as instruções para trocar a sua senha.")
        setErrorMessage(true)
        navigate("/");
      }).catch(error => {
        setErrorTitle("Erro ao trocar de senha");
        setErrorDescription("Algum erro ocorreu ao trocar sua senha.")
        setErrorMessage(true)
        reset();
        navigate("/");
      });

    } catch (err) {
      const validationErrors = {};

      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div className="login recuperacao-senha">
      <SecondaryHeader title="Lembrar Senha" />

      <section className="login-content">
        <h2>Informe o seu e-mail de cadastro para recuperar a sua senha.</h2>
        <Form onSubmit={handleFormSubmit} ref={formRef}>
          <Input name="email" id="email" label="Informe o seu e-mail" />

          <button type="submit">Enviar</button>
        </Form>
      </section>
    </div>
  );
};

export default EmailNovaSenha;

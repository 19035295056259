import React from "react";

const SvgBlog = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.516"
      height="17.488"
      viewBox="0 0 17.516 17.488"
    >
      <path
        id="writing"
        d="M15.483.148a.506.506,0,0,0-.716,0l-3.9,3.9H2.019A1.52,1.52,0,0,0,.5,5.569v7.087a1.52,1.52,0,0,0,1.519,1.519h.54v2.6a.506.506,0,0,0,.868.354l2.888-2.953h5.9a1.52,1.52,0,0,0,1.519-1.519V6.914l3.9-3.9a.506.506,0,0,0,0-.716ZM10.83,8.381,9.4,6.95l4.3-4.3,1.432,1.432ZM8.859,7.842,9.937,8.921l-1.849.77Zm3.858,4.814a.507.507,0,0,1-.506.506H6.1a.506.506,0,0,0-.362.152L3.571,15.532V13.668a.506.506,0,0,0-.506-.506H2.019a.507.507,0,0,1-.506-.506V5.569a.507.507,0,0,1,.506-.506H9.853L8.324,6.592a.517.517,0,0,0-.112.169l-1.4,3.364H4.077a.506.506,0,1,0,0,1.012H7.149a.512.512,0,0,0,.2-.041l3.671-1.529a.514.514,0,0,0,.169-.112l1.529-1.529ZM15.841,3.37,14.41,1.938l.716-.716,1.432,1.432Zm0,0"
        transform="translate(-0.4 0.1)"
        fill="#0d9648"
        stroke="#0d9648"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default SvgBlog;

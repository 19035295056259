import React from "react";
import ImgCursosProf from "../../../../assets/images/foto-destaque-cursos-profissionalizantes.jpg";
import ImgCursosProf1 from "../../../../assets/images/foto-galeria-cursos-profissionalizantes-1.jpg";
import ImgCursosProf2 from "../../../../assets/images/foto-galeria-cursos-profissionalizantes-2.jpg";
import ImgCursosProf3 from "../../../../assets/images/foto-galeria-cursos-profissionalizantes-3.jpg";
import ImgCursosProf4 from "../../../../assets/images/foto-galeria-cursos-profissionalizantes-4.jpg";
import ImgCursosProf5 from "../../../../assets/images/foto-galeria-cursos-profissionalizantes-5.jpg";
import Galery from "../../../../components/Galery";

const galeryImgs = [
  { src: ImgCursosProf1, alt: "Cursos Profissionalizantes 1" },
  { src: ImgCursosProf2, alt: "Cursos Profissionalizantes 2" },
  { src: ImgCursosProf3, alt: "Cursos Profissionalizantes 3" },
  { src: ImgCursosProf4, alt: "Cursos Profissionalizantes 4" },
  { src: ImgCursosProf5, alt: "Cursos Profissionalizantes 5" },
];

const CursosProf = () => {
  return (
    <div className="detalhes-iniciativa">
      <main className="banner-detalhes-iniciativa-mobile bg-cursos-profissionalizantes" />

      <section className="detalhes-iniciativa-content container">
        <div className="row gutters">
          <div className="col grid_6">
            <h2 className="subtitulo-verde">CURSOS PROFISSIONALIZANTES</h2>
            <p>
              Profissionalizar pessoas é descobrir aptidões e gerar
              oportunidades que tragam pertencimento e melhoria de vida e
              autoestima.
            </p>
          </div>
          <div className="col grid_6">
            <img src={ImgCursosProf} alt="Cursos Profissionalizantes" />
          </div>
        </div>
      </section>

      <Galery galeryImgs={galeryImgs} />
    </div>
  );
};

export default CursosProf;

import React from "react";

const Link = ({ href, children, ...props }) => {
  return (
    <a className="link-input" href={href} {...props}>
      {children}
    </a>
  );
};

export default Link;

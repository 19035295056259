import React from "react";
import { useApp } from "../../context/AppProvider";

import Modal from "./Modal";
import ItemBalancete from "../ItemBalancete";

const listaBalancetes = [
  { path: "https://public.mobi2buy.com/noiz/balancete.pdf", title: "Agosto 2024"}
]
const listaRelAnual = [
  { path: "https://public.mobi2buy.com/noiz/relatorioanual.pdf", title: "Relatório Anual 2023"}
]

const Balancetes = () => {
  const { balancetes, setBalancetes } = useApp();

  return (
    <Modal
      visibility={balancetes}
      setVisibility={setBalancetes}
      id="balancetes"
    >
      <h3>Transparência</h3>
      <div className="modal-area">
        <p>Confira mensalmente nosso balancete</p>
        <ul>
          {listaBalancetes.map(balancete => <ItemBalancete key={balancete.title} path={balancete.path} title={balancete.title}/>)}
        </ul>
        <ul>
          {listaRelAnual.map(relanual => <ItemBalancete key={relanual.title} path={relanual.path} title={relanual.title}/>)}
        </ul>
      </div>
    </Modal>
  );
};

export default Balancetes;

import React from "react";
import LogoMobi2buy from "../../assets/images/logo-mobi.png";
import ImgHumaita from "../../assets/images/logo-top2you.png";
import ImgRetornar from "../../assets/images/logo-retornar.png";
import ImgCasaShopping from "../../assets/images/logo-casaShopping.png";

import ImgMetropolitano from "../../assets/images/logo_shopping_metropolitano_barra.png";
import ImgSyn from "../../assets/images/logo_instituto_syn.png";
import ImgTjerj from "../../assets/images/Logo_PJERJ.png";
import ImgBagaggio from "../../assets/images/Logo_bagaggio.svg";

import PartnerItem from "./item";

const Partner = () => {
  return (
    <section className="parceiros">
      <div className="parceiros-content container">
        <h2 className="subtitulo-branco">Parcerias</h2>
        <ul>
          <PartnerItem src={LogoMobi2buy} alt="Mobi2Buy" link="https://mobi2buy.com/"></PartnerItem>
          <PartnerItem src={ImgHumaita} alt="Grupo Humaitá" link="http://humaitagroup.com.br/"></PartnerItem>
          <PartnerItem src={ImgRetornar} alt="Retornar" link="https://retornar.com.br/"></PartnerItem>
          <PartnerItem src={ImgCasaShopping} alt="Casa Shopping" link="https://www.casashopping.com/"></PartnerItem>
          <PartnerItem src={ImgMetropolitano} alt="Shopping Metropolitano Barra" link="https://www.shoppingmetropolitanobarra.com.br/"></PartnerItem>
          <PartnerItem src={ImgSyn} alt="Instituto SYN" link="https://www.institutosyn.org.br/"></PartnerItem>
          <PartnerItem src={ImgTjerj} alt="Tribunal de Justiça do Rio de Janeiro" link="https://www.tjrj.jus.br/"></PartnerItem>
          <PartnerItem src={ImgBagaggio} alt="Bagaggio" link="https://www.bagaggio.com.br/"></PartnerItem>
        </ul>
      </div>
    </section>
  );
};

export default Partner;

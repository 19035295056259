import React from "react"

const BlogItem = ({link, title, image, alt}) => {
    return <div>
    <div className="card">
      <a
        href={link}
        className="actions__card"
        target="_blank"
        rel="noreferrer"
      >
        <h3>{title}</h3>
        <img src={image} alt={title} />
      </a>
    </div>
  </div>
}

export default BlogItem
import React from "react";

const SvgClose = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.414"
      height="17.414"
      viewBox="0 0 17.414 17.414"
    >
      <g
        id="Group_6048"
        data-name="Group 6048"
        transform="translate(-245.793 -25.793)"
      >
        <line
          id="Line_3"
          data-name="Line 3"
          x2="16"
          y2="16"
          transform="translate(246.5 26.5)"
          fill="none"
          stroke="#4a4a4a"
          strokeWidth="2"
        />
        <line
          id="Line_4"
          data-name="Line 4"
          x1="16"
          y2="16"
          transform="translate(246.5 26.5)"
          fill="none"
          stroke="#4a4a4a"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default SvgClose;

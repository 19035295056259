import React from "react";

const Label = ({ htmlFor, children, labelHidden = false }) => {
  return (
    <label
      htmlFor={htmlFor}
      className="label"
      style={{ visibility: labelHidden ? "hidden" : "visible" }}
    >
      {children}
    </label>
  );
};

export default Label;

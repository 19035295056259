import React from "react";

const SvgNumber = () => {
  return (
    <svg
      id="call"
      xmlns="http://www.w3.org/2000/svg"
      width="15.803"
      height="15.803"
      viewBox="0 0 15.803 15.803"
    >
      <g id="Group_3046" data-name="Group 3046">
        <path
          id="Path_2884"
          data-name="Path 2884"
          d="M14.535,10.373a8.972,8.972,0,0,1-2.818-.449,1.288,1.288,0,0,0-1.253.264L8.688,11.529A9.82,9.82,0,0,1,4.273,7.115l1.3-1.731A1.277,1.277,0,0,0,5.889,4.09a8.986,8.986,0,0,1-.451-2.822A1.269,1.269,0,0,0,4.17,0h-2.9A1.269,1.269,0,0,0,0,1.268,14.551,14.551,0,0,0,14.535,15.8,1.269,1.269,0,0,0,15.8,14.535V11.641A1.27,1.27,0,0,0,14.535,10.373Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default SvgNumber;

import React from "react";
import BlueWave from "../../components/BlueWave";
import Contact from "../../components/Contact";
import SejaSocio from "../../components/Divisors/SejaSocio";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Partner from "../../components/Partner";
import PostMidias from "../../components/PostMidias";
import LogoSocio from "../../assets/images/logo-socio-sobre.png";
import IniciativasComp from "../../components/IniciativasComp";

const SobreSocioTransformador = () => {
  return (
    <>
      <Header />
      <div className="detalhes-socio">
        <main className="banner-detalhes-socio-mobile" />

        <section className="detalhes-socio-content container">
          <div className="row gutters">
            <div className="col grid_6">
              <h2 className="subtitulo-verde">SÓCIO-TRANSFORMADOR</h2>
              <p>
                Você pensa na criança. Você pensa no futuro. Você acredita na
                educação. Você acredita na cultura. Você acredita na evolução
                das pessoas. Você acredita que vidas podem ser transformadas.
                Você é Nóiz.
              </p>
              <p>
                Com apenas R$ 10,00 por mês, você se torna um Sócio
                Transformador e pode transformar a vida de muitas pessoas.
              </p>
            </div>
            <div className="col grid_6">
              <img src={LogoSocio} alt="Sessão de Cinema" />
            </div>
          </div>
        </section>
      </div>
      <IniciativasComp />
      <SejaSocio />
      <PostMidias />
      <BlueWave />
      <Partner />
      <Contact />
      <Footer />
    </>
  );
};

export default SobreSocioTransformador;

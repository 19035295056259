import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import "./assets/css/style.css";
import "./assets/css/slick-theme.css";
import "./assets/css/slick.css";
import "./assets/css/responsive.css";
import App from "./App";

Bugsnag.start({
  apiKey: "ae6df6aa8e9fc4ce0d0139c101864831",
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

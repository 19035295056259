import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import LogoSocio from "../../assets/images/logo-socio.png";
import apis from "../../services/apis";
import { useApp } from "../../context/AppProvider";

const monthNames = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];

const AreaSocioTransformador = () => {
  const navigate = useNavigate();
  const [subMonth, setSubMonth] = useState("janeiro");
  const [subYear, setSubYear] = useState(2022);
  const [user, setUser] = useState({});
  const { 
    setCancelSubscription,
    setErrorTitle,
    setErrorDescription,
    setErrorMessage,
    setBalancetes
  } = useApp();

  const openBalancetes = (e) => {
    e.preventDefault(true);
    setBalancetes(true);
  };

  localStorage.getItem("user");
  useEffect(_ => {
    if (!localStorage.getItem("accessToken")) navigate("/");
    setUser(JSON.parse(localStorage.getItem("user")));

    apis.get("/api/v1/subscriptions", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }).then((data) => {
      let date = new Date(data.data[0].created_at);
      setSubMonth(monthNames[date.getMonth()]);
      setSubYear(date.getFullYear());
    }).catch((error) => {
      setErrorTitle("Erro ao buscar informações");
      setErrorDescription(error.response.data.message);
      setErrorMessage(true);
      navigate("/");
    });
  }, [navigate]);

  const handleCancel = (e) => {
    e.preventDefault();
    setCancelSubscription(true);
  };

  return (
    <div className="cadastro area-socio">
      <SecondaryHeader title="SÓCIO TRANSFORMADOR" />

      <section className="sucesso container">
        <div className="col grid_8">
          <p>Bem vindo(a) {user.name}.</p>
          <p>
            Você é um dos Sócios Transformadores desde {subMonth} de {subYear}.
          </p>
        </div>
      </section>

      <section className="detalhes container">
        <div className="detalhes-content">
          <div className="col grid_6">
            <p>Com R$ 10,00 por mês, até agora você já ajudou</p>
            <p>com R$ 00,00 ao todo.</p>
            <a href="/" onClick={openBalancetes}>
              Ver prestação de contas
            </a>
          </div>
        </div>
      </section>

      <section className="credencial container">
        <div className="row gutters">
          <div className="credencial-content col grid_6">
            <h3>
              {user.name} {user.lastname}
            </h3>
            <img src={LogoSocio} alt="Sócio Transformador" />
            <p>
              Desde {subMonth} de {subYear}
            </p>
          </div>
          <div className="credencial-share col grid_6">
            <p>
              Compartilhe sua credencial de sócio e ajude a divulgar nosso
              trabalho.
            </p>
          </div>
        </div>
        <div className="col grid_6">
          <a className="btn-cancelar" href="/" onClick={handleCancel}>
            Cancelar sua contribuição
          </a>
        </div>
      </section>
    </div>
  );
};

export default AreaSocioTransformador;

import React from "react";
import LogoFooter from "../../assets/images/logo-noiz-footer.png";
import IconFacebook from "../../assets/images/ico-footer-facebook.svg";
import IconInstagran from "../../assets/images/ico-footer-instagran.svg";
import IconYoutube from "../../assets/images/ico-footer-youtube.svg";
import Complience from "../../assets/docs/Conduta_Noiz.pdf";
import FlexWrapper from "../FlexWrapper";
import SvgAddress from "../Svg/SvgAddress";
import SvgEmail from "../Svg/SvgEmail";
import SvgNumber from "../Svg/SvgNumber";
import { Link } from "react-router-dom";
import {useApp} from "../../context/AppProvider";


const Footer = () => {
  const { setNoizDocuments } = useApp();

  const openNoizDocuments = (e) => {
    e.preventDefault(true);
    setNoizDocuments(true);
  };

  return (
    <footer>
      <div className="container">
        <FlexWrapper version="6">
          <div className="col-1">
            <img src={LogoFooter} alt="Noiz" />
          </div>
          <div className="col-2">
            <nav>
              <ul>
                <li>
                  <a href="/#projeto">Projeto</a>
                </li>
                <li>
                  <a href="/#regiao">A Região</a>
                </li>
                <li>
                  <Link to="/iniciativas/caixote-do-saber">
                    Caixote do Saber
                  </Link>
                </li>
                <li>
                  <a href={Complience} target="_blank" rel="noreferrer">Código de Conduta</a>
                </li>
                <li>
                  <a href="/" onClick={openNoizDocuments}>Documentação</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-3">
            <h3>Contato</h3>
            <ul>
              <li>
                <i>
                  <SvgAddress />
                </i>
                <span>
                Rua Vila Nova Cruzada, 23 - Cidade de Deus (Karatê - Rocinha 2)
                </span>
              </li>
              <li>
                <i>
                  <SvgEmail />
                </i>
                <span>atendimento@noizprojetosocial.org</span>
              </li>
              <li>
                <i>
                  <SvgNumber />
                </i>
                <span>Whatsapp / Administração: <a href="https://wa.me/552120428367?text=Olá, Acessei o site e gostaria de informações" rel="noreferrer" target="_blank">+55 21 2042-8367</a></span>
              </li>
            </ul>
          </div>
          <div className="col-4">
            <ul>
              <li>
                <a
                  href="https://pt-br.facebook.com/pages/category/Social-Service/N%C3%93IZ-Projeto-Social-200939380531515/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IconFacebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/noizprojetosocial/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IconInstagran} alt="Instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCyk8uIHyMA0hg0AGqYKuDJg/videos?view=0&sort=p"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IconYoutube} alt="Youtube" />
                </a>
              </li>
            </ul>
          </div>
        </FlexWrapper>
      </div>
      <div className="copy">
        <h6>Copyright © NOIZ - Todos os Direitos Reservados.</h6>
        <p>
          Desenvolvido por{" "}
          <a href="https://mobi2buy.com/" target="_blank" rel="noreferrer">
            MOBI2BUY
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

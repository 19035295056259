import React, { useState } from "react";

const HelpMessage = () => {
  const [active, setActive] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setActive(!active);
  };
  const handleBlur = (e) => {
    e.preventDefault();
    setActive(false);
  };

  return (
    <div>
      <a
        href="/"
        onClick={handleClick}
        onBlur={handleBlur}
        className={`help-message${active ? " active" : ""}`}
      >
        Ajuda{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
        >
          <g
            id="iconfinder_icon-help-circled_211674"
            transform="translate(-32 -32)"
          >
            <path
              id="Path_14"
              data-name="Path 14"
              d="M39.5,32A7.5,7.5,0,1,0,47,39.5,7.5,7.5,0,0,0,39.5,32Zm.676,10.939a1,1,0,0,1-2-.06,1,1,0,0,1,2,.06Zm1.631-4.262a4.842,4.842,0,0,1-.85.76l-.439.3a1.161,1.161,0,0,0-.419.579,3.311,3.311,0,0,0-.07.626.137.137,0,0,1-.151.131H38.569c-.137,0-.154-.08-.151-.121a2.742,2.742,0,0,1,.331-1.383,4.4,4.4,0,0,1,1.189-1.018,1.385,1.385,0,0,0,.318-.325,1.161,1.161,0,0,0,.268-.666,1.184,1.184,0,0,0-.244-.763.967.967,0,0,0-.864-.382.945.945,0,0,0-.85.5,1.635,1.635,0,0,0-.147.914H36.788a2.526,2.526,0,0,1,.927-2.294,2.782,2.782,0,0,1,1.6-.419,3.231,3.231,0,0,1,2.073.6,2.074,2.074,0,0,1,.824,1.761A2.013,2.013,0,0,1,41.807,38.676Z"
              fill="#309ed9"
            />
          </g>
        </svg>
        <span>
          CVV é o código de segurança de 3 dígitos, impresso no verso do cartão.
        </span>
      </a>
    </div>
  );
};

export default HelpMessage;

import React from "react";

const Modal = ({
  element,
  children,
  className,
  visibility,
  setVisibility,
  ...props
}) => {
  const closeOverlay = (e) => {
    if (e.target === e.currentTarget) {
      setVisibility(false);
    }
  };

  const closeButton = () => {
    setVisibility(false);
  };

  return (
    <div
      className={`modal${
        visibility === null ? "" : visibility === true ? " active" : " disabled"
      }${className ? className : ""}`}
      onClick={closeOverlay}
      {...props}
    >
      <div className="content">
        <button className="close" onClick={closeButton}>
          X
        </button>
        {children || element}
      </div>
    </div>
  );
};

export default Modal;

import React from "react";

const FormRow = ({ children, ...rest }) => {
  return (
    <div className="form-row" {...rest}>
      {children}
    </div>
  );
};

export default FormRow;

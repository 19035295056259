import React from "react";
import {useApp} from "../../context/AppProvider";
import Modal from "./Modal";
import SvgDocument from "../Svg/SvgDocument";
import cnpjnoiz from "../../assets/docs/CNPJ Noiz.pdf";
import atanoiz from "../../assets/docs/Ata Noiz.pdf";
import estatutonoiz from "../../assets/docs/Estatuto Noiz.pdf";

const NoizDocuments = () => {
    const {noizdocuments, setNoizDocuments} = useApp();

    return (
        <Modal
            visibility={noizdocuments}
            setVisibility={setNoizDocuments}
            id="noizdocuments"
        >
            <h3>Documentação</h3>
            <div className="modal-area">
                <ul>
                    <li>
                        <a href={cnpjnoiz} target="_blank" rel="noreferrer">
                            <SvgDocument/> CNPJ Nóiz
                        </a>
                    </li>
                    <li>
                        <a href={atanoiz} target="_blank" rel="noreferrer">
                            <SvgDocument/> Ata
                        </a>
                    </li>
                    <li>
                        <a href={estatutonoiz} target="_blank" rel="noreferrer">
                            <SvgDocument/> Estatuto
                        </a>
                    </li>
                </ul>
            </div>
        </Modal>
    );
};

export default NoizDocuments;

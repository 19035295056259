import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Home from "../pages/Home";
import AppProvider from "../context/AppProvider";
import Iniciativas from "../pages/Iniciativas";
import Iniciativa from "../pages/Iniciativas/Iniciativa";
import Login from "../pages/Login";
import Cadastro from "../pages/Cadastro";
import NovaSenha from "../pages/NovaSenha";
import EmailNovaSenha from "../pages/EmailNovaSenha";
import CadastroSucesso from "../pages/CadastroSucesso";
import AreaSocioTransformador from "../pages/AreaSocioTransformador";
import Congratulations from "../components/Modals/Congratulations";
import Balancetes from "../components/Modals/Balancetes";
import SobreSocioTransformador from "../pages/SobreSocioTransformador";
import CancelSubscription from "../components/Modals/CancelSubscription";
import RegisterError from "../components/Modals/RegisterError";
import ErrorMessage from "../components/Modals/ErrorMessage";
import NoizDocuments from "../components/Modals/NoizDocuments";


const MainRouter = () => {
  return (
    <>
      <BrowserRouter>
        <AppProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/iniciativas" element={<Outlet />}>
              <Route index element={<Iniciativas />} />
              <Route path=":iniciativaId" element={<Iniciativa />} />
              <Route
                path=":iniciativaId/*"
                element={<Navigate to="/iniciativas" />}
              />
            </Route>
            <Route
              path="/area-socio-transformador"
              element={<AreaSocioTransformador />}
            />
            <Route
              path="/sobre-socio-transformador"
              element={<SobreSocioTransformador />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/doacao" element={<Cadastro />} />
            <Route path="/sucesso" element={<CadastroSucesso />} />
            <Route path="/password/recover/:hash" element={<NovaSenha />} />
            <Route path="/email/recover" element={<EmailNovaSenha />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Congratulations />
          <Balancetes />
          <CancelSubscription />
          <RegisterError />
          <ErrorMessage />
          <NoizDocuments />
        </AppProvider>
      </BrowserRouter>
    </>
  );
};

export default MainRouter;

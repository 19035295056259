import React from "react";
import Button from "../Button";

const Pix = () => {
  const pix = "33.764.799/0001-41";

  function copyPix() {
    navigator.clipboard.writeText(pix);
    alert("Pix copiado!");
  }

  return (
    <section className="seja pix">
      <div className="pix-content container">
        <div className="left">
          <h2>
          Ajude-nos a realizar ainda mais!
          </h2>
          <p>
            Para fazer uma doação rápida e fácil,<br/> basta usar o <strong>Pix</strong>!
          </p>
        </div>
        <div className="right">
          <div>
            <p>Nossa chave é o </p>
            <p>
            <strong id="pix">
                CNPJ: <span>{pix}</span>
              </strong>
            </p>
            <p>
              <strong>Nóiz Projeto Social</strong>
            </p>
            <br></br>
            <p>
            Juntos, fazemos a diferença!
            </p>
          </div>
          <div>
            <Button className="btn-gradient-secondary" onClick={copyPix}>
              Copiar código pix
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pix;

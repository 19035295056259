import React from "react";
import ImgAulaTeatro from "../../../../assets/images/foto-destaque-aula-teatro.jpg";
import ImgAulaTeatro1 from "../../../../assets/images/foto-galeria-aula-teatro-1.jpg";
import ImgAulaTeatro2 from "../../../../assets/images/foto-galeria-aula-teatro-2.jpg";
import Galery from "../../../../components/Galery";

const galeryImgs = [
  { src: ImgAulaTeatro1, alt: "Aula de Teatro 1" },
  { src: ImgAulaTeatro2, alt: "Aula de Teatro 2" },
];

const AulaTeatro = () => {
  return (
    <div className="detalhes-iniciativa">
      <main className="banner-detalhes-iniciativa-mobile bg-aula-teatro" />

      <section className="detalhes-iniciativa-content container">
        <div className="row gutters">
          <div className="col grid_6">
            <h2 className="subtitulo-verde">Aula de Teatro</h2>
            <p>
              A arte é transformadora. Capaz de integrar, socializar ideias e
              desenvolver sua aprendizagem de forma lúdica, o teatro desenvolve
              também a parte indutiva e racional através da expressão de suas
              emoções, levando ao conhecimento de si mesmo e do mundo o cerca.
            </p>
          </div>
          <div className="col grid_6">
            <img src={ImgAulaTeatro} alt="Aula de Teatro" />
          </div>
        </div>
      </section>

      <Galery galeryImgs={galeryImgs} />
    </div>
  );
};

export default AulaTeatro;

import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import ErrorMessage from "./ErrorMessage";
import FormRow from "./FormRow";
import Label from "./Label";

const Password = ({
  type = "text",
  id,
  name,
  label = null,
  placeholder = "",
  defaultValue = "",
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <FormRow>
      {label && <Label htmlFor={id}>{label}</Label>}
      <input
        type={visible ? "text" : "password"}
        id={id}
        name={name}
        ref={inputRef}
        className={error ? "error" : ""}
        placeholder={placeholder}
        // defaultValue={defaultValue}
        {...rest}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <button
        type="button"
        onClick={toggleVisibility}
        className="btn-pass-visible"
      >
        <i className="pass-visible">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.736"
            height="14.745"
            viewBox="0 0 24.736 14.745"
          >
            <g id="eye" transform="translate(0 -98.725)">
              <path
                id="Path_172"
                data-name="Path 172"
                d="M12.368,98.725c-4.726,0-9.012,2.586-12.174,6.785a.979.979,0,0,0,0,1.169c3.162,4.2,7.448,6.791,12.174,6.791s9.012-2.586,12.174-6.785a.979.979,0,0,0,0-1.169C21.38,101.311,17.094,98.725,12.368,98.725Zm.339,12.564a5.2,5.2,0,1,1,4.853-4.853A5.206,5.206,0,0,1,12.707,111.289Zm-.157-2.4a2.8,2.8,0,1,1,2.616-2.616A2.8,2.8,0,0,1,12.55,108.891Z"
                fill="#adadad"
              />
            </g>
          </svg>
        </i>
      </button>
    </FormRow>
  );
};

export default Password;

import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import FormRow from "./FormRow";
import Label from "./Label";
import ErrorMessage from "./ErrorMessage";

function Select({
  name,
  label = null,
  options,
  className = "",
  placeholder,
  defaultValue,
  labelHidden = false,
  ...rest
}) {
  const { fieldName, registerField, error } = useField(name);
  const inputRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue("");
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="input-wrapper">
      {label && (
        <Label labelHidden={labelHidden} htmlFor={name}>
          {label}
        </Label>
      )}
      <FormRow>
        <select
          ref={inputRef}
          defaultValue={defaultValue}
          name={name}
          id={name}
          className={error ? "error" : ""}
          {...rest}
        >
          <option value="">{placeholder || label}</option>
          {options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label || item.value}
            </option>
          ))}
        </select>
      </FormRow>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}

export default Select;

import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo-noiz.png";
import SvgBlog from "../Svg/SvgBlog";
import SvgBalancetes from "../Svg/SvgBalancetes";
import SvgContact from "../Svg/SvgContact";
import SvgLogin from "../Svg/SvgLogin";
import { useApp } from "../../context/AppProvider";

const Header = () => {
  const { setBalancetes } = useApp();

  const openBalancetes = (e) => {
    e.preventDefault(true);
    setBalancetes(true);
  };

  return (
    <header className="header">
      <div className="container">
        <Link to="/" className="header-logo">
          <img src={Logo} alt="Noiz" />
        </Link>

        <nav className="menu-1">
          <ul>
            <li>
              <a href="/#projeto">projeto</a>
            </li>
            <li>
              <a href="/#regiao">a região</a>
            </li>
            <li>
              <Link to="/iniciativas">iniciativas</Link>
            </li>
          </ul>
        </nav>

        <nav className="menu-2">
          <ul>
            <li>
              <a href="/" onClick={openBalancetes}>
                <SvgBalancetes />
                <span>balancete</span>
              </a>
            </li>
            <li>
              <a href="/#blog">
                <SvgBlog />
                <span>blog</span>
              </a>
            </li>
            <li>
              <a href="/#contato">
                <SvgContact />
                <span>contato</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import { useApp } from "../../context/AppProvider";
import Modal from "./Modal";

const Congratulations = () => {
  const { congratulations, setCongratulations } = useApp();

  return (
    <Modal visibility={congratulations} setVisibility={setCongratulations}>
      <h3>Obrigado por visitar nosso site!</h3>

      <div className="modal-area">
        <p>
          Quer saber mais sobre o projeto e nos ajudar a construir o futuro,
          acesse nossos canais.
        </p>

        <p>
          Ficaremos muito gratos pelo seu contato e se for poss&iacute;vel sua
          ajuda.
        </p>
      </div>
    </Modal>
  );
};

export default Congratulations;

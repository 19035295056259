import React from "react";

const SvgBalancetes = () => {
  return (
    <svg
      id="money-bag"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="Caminho_2896"
        data-name="Caminho 2896"
        d="M7.656,166.062a13.886,13.886,0,0,0,4.14-.5,2.008,2.008,0,0,1,.582-1.84,2.04,2.04,0,0,1,0-2.93,2.04,2.04,0,0,1,0-2.93,2.037,2.037,0,0,1,.224-3.114,8.31,8.31,0,0,0-1.111-.857,1.148,1.148,0,0,0,.268-.717A1.172,1.172,0,0,0,10.586,152H4.727a1.172,1.172,0,0,0-1.172,1.172,1.148,1.148,0,0,0,.267.715A8.386,8.386,0,0,0,0,160.789C0,165.516,4.386,166.062,7.656,166.062Zm-.006-5.688a1.753,1.753,0,0,1-.58-3.41V156.1a.586.586,0,0,1,1.172,0v.868a1.755,1.755,0,0,1,1.166,1.648.586.586,0,1,1-1.172,0,.586.586,0,1,0-.586.586,1.754,1.754,0,0,1,.592,3.406v.524a.586.586,0,0,1-1.172,0v-.52a1.755,1.755,0,0,1-1.178-1.652.586.586,0,1,1,1.172,0,.586.586,0,1,0,.586-.586Zm0,0"
        transform="translate(0 -146.062)"
        fill="#0d9648"
      />
      <path
        id="Caminho_2897"
        data-name="Caminho 2897"
        d="M337.191,467h-5.312a.879.879,0,0,0,0,1.758h5.313a.879.879,0,0,0,0-1.758Zm0,0"
        transform="translate(-318.07 -448.758)"
        fill="#0d9648"
      />
      <path
        id="Caminho_2898"
        data-name="Caminho 2898"
        d="M337.191,392h-5.312a.879.879,0,0,0,0,1.758h5.313a.879.879,0,0,0,0-1.758Zm0,0"
        transform="translate(-318.07 -376.688)"
        fill="#0d9648"
      />
      <path
        id="Caminho_2899"
        data-name="Caminho 2899"
        d="M337.191,317h-5.312a.879.879,0,0,0,0,1.758h5.313a.879.879,0,0,0,0-1.758Zm0,0"
        transform="translate(-318.07 -304.617)"
        fill="#0d9648"
      />
      <path
        id="Caminho_2900"
        data-name="Caminho 2900"
        d="M337.191,242h-5.312a.879.879,0,0,0,0,1.758h5.313a.879.879,0,0,0,0-1.758Zm0,0"
        transform="translate(-318.07 -232.547)"
        fill="#0d9648"
      />
      <path
        id="Caminho_2901"
        data-name="Caminho 2901"
        d="M96.751,1.172a1.753,1.753,0,0,0-3.308,0H91.586a.585.585,0,0,0-.556.771l.915,2.822h6.313l.915-2.822a.585.585,0,0,0-.556-.771Zm0,0"
        transform="translate(-87.445)"
        fill="#0d9648"
      />
    </svg>
  );
};

export default SvgBalancetes;

import React, {createContext, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const AppContext = createContext();

const AppProvider = ({children}) => {
    const [balancetes, setBalancetes] = useState(null);
    const [noizdocuments, setNoizDocuments] = useState(null);
    const [congratulations, setCongratulations] = useState(null);
    const [cancelSubscription, setCancelSubscription] = useState(null);
    const [errorTitle, setErrorTitle] = useState(null);
    const [errorDescription, setErrorDescription] = useState(
        "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde."
    );
    const [errorMessage, setErrorMessage] = useState(null);
    const [registerError, setRegisterError] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => window.scrollTo(0, 0), [location.pathname]);

    return (
        <AppContext.Provider
            value={{
                balancetes,
                setBalancetes,
                congratulations,
                setCongratulations,
                cancelSubscription,
                setCancelSubscription,
                errorTitle,
                setErrorTitle,
                errorDescription,
                setErrorDescription,
                errorMessage,
                setErrorMessage,
                registerError,
                setRegisterError,
                loading,
                setLoading,
                noizdocuments,
                setNoizDocuments,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useApp = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error(
            "You must surround your components with a AppProvider before call useApp"
        );
    }

    return context;
};

export default AppProvider;

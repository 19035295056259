import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppProvider";
import Modal from "./Modal";
import apis from "../../services/apis";

const CancelSubscription = () => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const { 
    cancelSubscription, 
    setCancelSubscription, 
    loading, 
    setLoading,
    setErrorTitle,
    setErrorDescription,
    setErrorMessage 
  } = useApp();
   
  const handleConfirm = async () => {
    setLoading(true);
    if (!localStorage.getItem("accessToken")) navigate("/");
    apis.delete("/api/v1/subscriptions", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }).then((data) => {
      setConfirm(true);
      localStorage.removeItem("accessToken");
      setLoading(false);
      navigate("/");
    }).catch((error) => {
      setCancelSubscription(false);
      setErrorTitle('Erro No cancelamento');
      setErrorDescription("Algum erro ocorreu no seu cancelamento.");
      setErrorMessage(true);
      localStorage.removeItem("accessToken");
      setLoading(false);
      navigate("/");
    });
  };

  return (
    <Modal
      visibility={cancelSubscription}
      setVisibility={setCancelSubscription}
    >
      {confirm ? (
        <>
          <h3>Inscrição cancelada</h3>

          <div className="modal-area">
            <p>
              Você receberá um e-mail confirmando o cancelamento da sua
              inscrição.
            </p>
          </div>
        </>
      ) : (
        <>
          <h3>Confirmar cancelamento</h3>

          <div className="modal-area">
            <p>Você realmente deseja cancelar a sua inscrição?</p>
            <button
              className="btn-modal"
              onClick={handleConfirm}
              disabled={loading}
            >
              {loading ? "Confirmando..." : "Confirmar"}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CancelSubscription;

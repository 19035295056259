export const countries = [
  {
    value: "Afeganistão",
    code: "AF",
  },
  {
    value: "África do Sul",
    code: "ZA",
  },
  {
    value: "Akrotiri",
    code: "",
  },
  {
    value: "Albânia",
    code: "AL",
  },
  {
    value: "Alemanha",
    code: "DE",
  },
  {
    value: "Andorra",
    code: "AD",
  },
  {
    value: "Angola",
    code: "AO",
  },
  {
    value: "Anguila",
    code: "AI",
  },
  {
    value: "Antárctida",
    code: "AQ",
  },
  {
    value: "Antígua e Barbuda",
    code: "AG",
  },
  {
    value: "Arábia Saudita",
    code: "SA",
  },
  {
    value: "Arctic Ocean",
    code: "",
  },
  {
    value: "Argélia",
    code: "DZ",
  },
  {
    value: "Argentina",
    code: "AR",
  },
  {
    value: "Arménia",
    code: "AM",
  },
  {
    value: "Aruba",
    code: "AW",
  },
  {
    value: "Ashmore and Cartier Islands",
    code: "",
  },
  {
    value: "Atlantic Ocean",
    code: "",
  },
  {
    value: "Austrália",
    code: "AU",
  },
  {
    value: "Áustria",
    code: "AT",
  },
  {
    value: "Azerbaijão",
    code: "AZ",
  },
  {
    value: "Bahamas",
    code: "BS",
  },
  {
    value: "Bangladeche",
    code: "BD",
  },
  {
    value: "Barbados",
    code: "BB",
  },
  {
    value: "Barém",
    code: "BH",
  },
  {
    value: "Bélgica",
    code: "BE",
  },
  {
    value: "Belize",
    code: "BZ",
  },
  {
    value: "Benim",
    code: "BJ",
  },
  {
    value: "Bermudas",
    code: "BM",
  },
  {
    value: "Bielorrússia",
    code: "BY",
  },
  {
    value: "Birmânia",
    code: "",
  },
  {
    value: "Bolívia",
    code: "BO",
  },
  {
    value: "Bósnia e Herzegovina",
    code: "BA",
  },
  {
    value: "Botsuana",
    code: "BW",
  },
  {
    value: "Brasil",
    code: "BR",
  },
  {
    value: "Brunei",
    code: "BN",
  },
  {
    value: "Bulgária",
    code: "BG",
  },
  {
    value: "Burquina Faso",
    code: "BF",
  },
  {
    value: "Burúndi",
    code: "BI",
  },
  {
    value: "Butão",
    code: "BT",
  },
  {
    value: "Cabo Verde",
    code: "CV",
  },
  {
    value: "Camarões",
    code: "CM",
  },
  {
    value: "Camboja",
    code: "KH",
  },
  {
    value: "Canadá",
    code: "CA",
  },
  {
    value: "Catar",
    code: "QA",
  },
  {
    value: "Cazaquistão",
    code: "KZ",
  },
  {
    value: "Chade",
    code: "TD",
  },
  {
    value: "Chile",
    code: "CL",
  },
  {
    value: "China",
    code: "CN",
  },
  {
    value: "Chipre",
    code: "CY",
  },
  {
    value: "Clipperton Island",
    code: "",
  },
  {
    value: "Colômbia",
    code: "CO",
  },
  {
    value: "Comores",
    code: "KM",
  },
  {
    value: "Congo-Brazzaville",
    code: "",
  },
  {
    value: "Congo-Kinshasa",
    code: "",
  },
  {
    value: "Coral Sea Islands",
    code: "",
  },
  {
    value: "Coreia do Norte",
    code: "KP",
  },
  {
    value: "Coreia do Sul",
    code: "KR",
  },
  {
    value: "Costa do Marfim",
    code: "CI",
  },
  {
    value: "Costa Rica",
    code: "CR",
  },
  {
    value: "Croácia",
    code: "HR",
  },
  {
    value: "Cuba",
    code: "CU",
  },
  {
    value: "Curacao",
    code: "CW",
  },
  {
    value: "Dhekelia",
    code: "",
  },
  {
    value: "Dinamarca",
    code: "DK",
  },
  {
    value: "Domínica",
    code: "DM",
  },
  {
    value: "Egipto",
    code: "EG",
  },
  {
    value: "Emiratos Árabes Unidos",
    code: "AE",
  },
  {
    value: "Equador",
    code: "EC",
  },
  {
    value: "Eritreia",
    code: "ER",
  },
  {
    value: "Eslováquia",
    code: "SK",
  },
  {
    value: "Eslovénia",
    code: "SI",
  },
  {
    value: "Espanha",
    code: "ES",
  },
  {
    value: "Estados Unidos",
    code: "US",
  },
  {
    value: "Estónia",
    code: "EE",
  },
  {
    value: "Etiópia",
    code: "ET",
  },
  {
    value: "Faroé",
    code: "",
  },
  {
    value: "Fiji",
    code: "FJ",
  },
  {
    value: "Filipinas",
    code: "PH",
  },
  {
    value: "Finlândia",
    code: "FI",
  },
  {
    value: "França",
    code: "FR",
  },
  {
    value: "Gabão",
    code: "GA",
  },
  {
    value: "Gâmbia",
    code: "GM",
  },
  {
    value: "Gana",
    code: "GH",
  },
  {
    value: "Gaza Strip",
    code: "",
  },
  {
    value: "Geórgia",
    code: "GE",
  },
  {
    value: "Geórgia do Sul e Sandwich do Sul",
    code: "",
  },
  {
    value: "Gibraltar",
    code: "GI",
  },
  {
    value: "Granada",
    code: "GD",
  },
  {
    value: "Grécia",
    code: "GR",
  },
  {
    value: "Gronelândia",
    code: "GL",
  },
  {
    value: "Guame",
    code: "GU",
  },
  {
    value: "Guatemala",
    code: "GT",
  },
  {
    value: "Guernsey",
    code: "GG",
  },
  {
    value: "Guiana",
    code: "GY",
  },
  {
    value: "Guiné",
    code: "GN",
  },
  {
    value: "Guiné Equatorial",
    code: "GQ",
  },
  {
    value: "Guiné-Bissau",
    code: "GW",
  },
  {
    value: "Haiti",
    code: "HT",
  },
  {
    value: "Honduras",
    code: "HN",
  },
  {
    value: "Hong Kong",
    code: "HK",
  },
  {
    value: "Hungria",
    code: "HU",
  },
  {
    value: "Iémen",
    code: "YE",
  },
  {
    value: "Ilha Bouvet",
    code: "",
  },
  {
    value: "Ilha do Natal",
    code: "",
  },
  {
    value: "Ilha Norfolk",
    code: "",
  },
  {
    value: "Ilhas Caimão",
    code: "KY",
  },
  {
    value: "Ilhas Cook",
    code: "CK",
  },
  {
    value: "Ilhas dos Cocos",
    code: "CC",
  },
  {
    value: "Ilhas Falkland",
    code: "",
  },
  {
    value: "Ilhas Heard e McDonald",
    code: "",
  },
  {
    value: "Ilhas Marshall",
    code: "MH",
  },
  {
    value: "Ilhas Salomão",
    code: "SB",
  },
  {
    value: "Ilhas Turcas e Caicos",
    code: "TC",
  },
  {
    value: "Ilhas Virgens Americanas",
    code: "VI",
  },
  {
    value: "Ilhas Virgens Britânicas",
    code: "VG",
  },
  {
    value: "Índia",
    code: "IN",
  },
  {
    value: "Indian Ocean",
    code: "",
  },
  {
    value: "Indonésia",
    code: "ID",
  },
  {
    value: "Irão",
    code: "IR",
  },
  {
    value: "Iraque",
    code: "IQ",
  },
  {
    value: "Irlanda",
    code: "IE",
  },
  {
    value: "Islândia",
    code: "IS",
  },
  {
    value: "Israel",
    code: "IL",
  },
  {
    value: "Itália",
    code: "IT",
  },
  {
    value: "Jamaica",
    code: "JM",
  },
  {
    value: "Jan Mayen",
    code: "",
  },
  {
    value: "Japão",
    code: "JP",
  },
  {
    value: "Jersey",
    code: "JE",
  },
  {
    value: "Jibuti",
    code: "",
  },
  {
    value: "Jordânia",
    code: "JO",
  },
  {
    value: "Kosovo",
    code: "XK",
  },
  {
    value: "Kuwait",
    code: "KW",
  },
  {
    value: "Laos",
    code: "LA",
  },
  {
    value: "Lesoto",
    code: "LS",
  },
  {
    value: "Letónia",
    code: "LV",
  },
  {
    value: "Líbano",
    code: "LB",
  },
  {
    value: "Libéria",
    code: "LR",
  },
  {
    value: "Líbia",
    code: "LY",
  },
  {
    value: "Listenstaine",
    code: "LI",
  },
  {
    value: "Lituânia",
    code: "LT",
  },
  {
    value: "Luxemburgo",
    code: "LU",
  },
  {
    value: "Macau",
    code: "MO",
  },
  {
    value: "Macedónia",
    code: "MK",
  },
  {
    value: "Madagáscar",
    code: "MG",
  },
  {
    value: "Malásia",
    code: "MY",
  },
  {
    value: "Malávi",
    code: "MW",
  },
  {
    value: "Maldivas",
    code: "MV",
  },
  {
    value: "Mali",
    code: "ML",
  },
  {
    value: "Malta",
    code: "MT",
  },
  {
    value: "Man, Isle of",
    code: "",
  },
  {
    value: "Marianas do Norte",
    code: "",
  },
  {
    value: "Marrocos",
    code: "MA",
  },
  {
    value: "Maurícia",
    code: "MU",
  },
  {
    value: "Mauritânia",
    code: "MR",
  },
  {
    value: "México",
    code: "MX",
  },
  {
    value: "Micronésia",
    code: "FM",
  },
  {
    value: "Moçambique",
    code: "MZ",
  },
  {
    value: "Moldávia",
    code: "MD",
  },
  {
    value: "Mónaco",
    code: "MC",
  },
  {
    value: "Mongólia",
    code: "MN",
  },
  {
    value: "Monserrate",
    code: "MS",
  },
  {
    value: "Montenegro",
    code: "ME",
  },
  {
    value: "Mundo",
    code: "",
  },
  {
    value: "Namíbia",
    code: "NA",
  },
  {
    value: "Nauru",
    code: "NR",
  },
  {
    value: "Navassa Island",
    code: "",
  },
  {
    value: "Nepal",
    code: "NP",
  },
  {
    value: "Nicarágua",
    code: "NI",
  },
  {
    value: "Níger",
    code: "NE",
  },
  {
    value: "Nigéria",
    code: "NG",
  },
  {
    value: "Niue",
    code: "NU",
  },
  {
    value: "Noruega",
    code: "NO",
  },
  {
    value: "Nova Caledónia",
    code: "NC",
  },
  {
    value: "Nova Zelândia",
    code: "NZ",
  },
  {
    value: "Omã",
    code: "OM",
  },
  {
    value: "Pacific Ocean",
    code: "",
  },
  {
    value: "Países Baixos",
    code: "NL",
  },
  {
    value: "Palau",
    code: "PW",
  },
  {
    value: "Panamá",
    code: "PA",
  },
  {
    value: "Papua-Nova Guiné",
    code: "PG",
  },
  {
    value: "Paquistão",
    code: "PK",
  },
  {
    value: "Paracel Islands",
    code: "",
  },
  {
    value: "Paraguai",
    code: "PY",
  },
  {
    value: "Peru",
    code: "PE",
  },
  {
    value: "Pitcairn",
    code: "",
  },
  {
    value: "Polinésia Francesa",
    code: "PF",
  },
  {
    value: "Polónia",
    code: "PL",
  },
  {
    value: "Porto Rico",
    code: "PR",
  },
  {
    value: "Portugal",
    code: "PT",
  },
  {
    value: "Quénia",
    code: "KE",
  },
  {
    value: "Quirguizistão",
    code: "KG",
  },
  {
    value: "Quiribáti",
    code: "",
  },
  {
    value: "Reino Unido",
    code: "GB",
  },
  {
    value: "República Centro-Africana",
    code: "",
  },
  {
    value: "República Dominicana",
    code: "DO",
  },
  {
    value: "Roménia",
    code: "RO",
  },
  {
    value: "Ruanda",
    code: "RW",
  },
  {
    value: "Rússia",
    code: "RU",
  },
  {
    value: "Salvador",
    code: "",
  },
  {
    value: "Samoa",
    code: "WS",
  },
  {
    value: "Samoa Americana",
    code: "AS",
  },
  {
    value: "Santa Helena",
    code: "SH",
  },
  {
    value: "Santa Lúcia",
    code: "LC",
  },
  {
    value: "São Bartolomeu",
    code: "BL",
  },
  {
    value: "São Cristóvão e Neves",
    code: "KN",
  },
  {
    value: "São Marinho",
    code: "",
  },
  {
    value: "São Martinho",
    code: "MF",
  },
  {
    value: "São Pedro e Miquelon",
    code: "PM",
  },
  {
    value: "São Tomé e Príncipe",
    code: "ST",
  },
  {
    value: "São Vicente e Granadinas",
    code: "VC",
  },
  {
    value: "Sara Ocidental",
    code: "",
  },
  {
    value: "Seicheles",
    code: "SC",
  },
  {
    value: "Senegal",
    code: "SN",
  },
  {
    value: "Serra Leoa",
    code: "SL",
  },
  {
    value: "Sérvia",
    code: "RS",
  },
  {
    value: "Singapura",
    code: "SG",
  },
  {
    value: "Sint Maarten",
    code: "SX",
  },
  {
    value: "Síria",
    code: "SY",
  },
  {
    value: "Somália",
    code: "SO",
  },
  {
    value: "Southern Ocean",
    code: "",
  },
  {
    value: "Spratly Islands",
    code: "",
  },
  {
    value: "Sri Lanca",
    code: "LK",
  },
  {
    value: "Suazilândia",
    code: "",
  },
  {
    value: "Sudão",
    code: "SD",
  },
  {
    value: "Sudão do Sul",
    code: "SS",
  },
  {
    value: "Suécia",
    code: "SE",
  },
  {
    value: "Suíça",
    code: "CH",
  },
  {
    value: "Suriname",
    code: "SR",
  },
  {
    value: "Svalbard e Jan Mayen",
    code: "",
  },
  {
    value: "Tailândia",
    code: "TH",
  },
  {
    value: "Taiwan",
    code: "TW",
  },
  {
    value: "Tajiquistão",
    code: "TJ",
  },
  {
    value: "Tanzânia",
    code: "TZ",
  },
  {
    value: "Território Britânico do Oceano Índico",
    code: "IO",
  },
  {
    value: "Territórios Austrais Franceses",
    code: "TF",
  },
  {
    value: "Timor Leste",
    code: "TL",
  },
  {
    value: "Togo",
    code: "TG",
  },
  {
    value: "Tokelau",
    code: "TK",
  },
  {
    value: "Tonga",
    code: "TO",
  },
  {
    value: "Trindade e Tobago",
    code: "TT",
  },
  {
    value: "Tunísia",
    code: "TN",
  },
  {
    value: "Turquemenistão",
    code: "TM",
  },
  {
    value: "Turquia",
    code: "TR",
  },
  {
    value: "Tuvalu",
    code: "TV",
  },
  {
    value: "Ucrânia",
    code: "UA",
  },
  {
    value: "Uganda",
    code: "UG",
  },
  {
    value: "União Europeia",
    code: "",
  },
  {
    value: "Uruguai",
    code: "UY",
  },
  {
    value: "Usbequistão",
    code: "UZ",
  },
  {
    value: "Vanuatu",
    code: "VU",
  },
  {
    value: "Vaticano",
    code: "VA",
  },
  {
    value: "Venezuela",
    code: "VE",
  },
  {
    value: "Vietname",
    code: "VN",
  },
  {
    value: "Wake Island",
    code: "",
  },
  {
    value: "Wallis e Futuna",
    code: "WF",
  },
  {
    value: "West Bank",
    code: "",
  },
  {
    value: "Zâmbia",
    code: "ZM",
  },
  {
    value: "Zimbabué",
    code: "ZW",
  },
];

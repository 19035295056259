import React, { useRef } from "react";
import { Form } from "@unform/web";
import { contactSchema } from "../../utils/schemas";
import * as yup from "yup";
import Button from "../Button";
import FlexWrapper from "../FlexWrapper";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";

const Contact = () => {
  const formRef = useRef(null);

  const handleFormSubmit = async (data, { reset }) => {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = yup.object().shape(contactSchema);

      await schema.validate(data, {
        abortEarly: false,
      });

      // Validation passed

      reset();
    } catch (err) {
      const validationErrors = {};

      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <section className="contato" id="contato">
      <div className="contato-content container">
        <FlexWrapper>
          <div>
            <h2 className="subtitulo-branco">Entre em contato</h2>

            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <Input id="name" name="name" placeholder="Nome" type="text" />
              <Input id="email" name="email" placeholder="E-mail" />
              <Input
                id="subject"
                name="subject"
                placeholder="Assunto"
                type="text"
              />
              <Textarea
                id="message"
                name="message"
                placeholder="Escreva aqui a sua mensagem"
              />
              <Button type="submit">Enviar</Button>
            </Form>
          </div>

          <div className="contact__map">
            <iframe
              title="Entre em contato"
              src="https://maps.google.com/maps?q=Noiz%20Projeto%20Social&t=&z=17&ie=UTF8&iwloc=&output=embed"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </FlexWrapper>
      </div>
    </section>
  );
};

export default Contact;
